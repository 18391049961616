import { DESTINATION_SEARCH_TYPE } from '@/constants/business-trip2';

const getDestinationFunc = ($API, russianRegions) => async (
  regionName,
  areaName,
  cityName,
  settlementName,
) => {
  let area = null;
  let city = null;
  let settlement = null;

  const region = russianRegions.find((item) => {
    const itemName = `${item.name} ${item.abbrev}`;
    return itemName.toLowerCase() === regionName.toLowerCase();
  });

  if (areaName) {
    area = await $API.businessTrip.getAddresses({
      searchType: DESTINATION_SEARCH_TYPE.area,
      regionCode: region.code,
    })
      .then((response) => response.data.find((item) => {
        const itemName = `${item.name} ${item.abbrev}`;
        return itemName.toLowerCase() === areaName.toLowerCase();
      }));
  }

  let districtCode = null;
  if (area) districtCode = area.code;
  else districtCode = region.code;

  if (cityName) {
    city = await $API.businessTrip.getAddresses({
      searchType: DESTINATION_SEARCH_TYPE.city,
      regionCode: region.code,
      districtCode,
    })
      .then((response) => response.data.find((item) => {
        const itemName = `${item.name} ${item.abbrev}`;
        return itemName.toLowerCase() === cityName.toLowerCase();
      }));
  }

  let cityCode = null;
  if (city) cityCode = city.code;
  else if (area) cityCode = area.code;
  else cityCode = region.code;

  if (settlementName) {
    settlement = await $API.businessTrip.getAddresses({
      searchType: DESTINATION_SEARCH_TYPE.settlement,
      regionCode: region.code,
      districtCode,
      cityCode,
    })
      .then((response) => response.data.find((item) => {
        const itemName = `${item.name} ${item.abbrev}`;
        return itemName.toLowerCase() === settlementName.toLowerCase();
      }));
  }

  return {
    region,
    area,
    city,
    settlement,
  };
};

export default getDestinationFunc;
