<template>
  <div class="common-data">
    <validation-observer ref="common">
      <div class="common-data__row">
        <div class="common-data__cell">
          <validation-provider
            name="Cотрудник"
            rules="required|dontGPH"
            v-slot="{ errors }"
          >
            <user-search
              placeholder="Сотрудник"
              :error-message="errors[0]"
              v-model="localActiveUser"
            />
          </validation-provider>
        </div>
      </div>

      <div class="common-data__row">
        <div class="common-data__cell">
          <validation-provider
            name="Мобильный телефон"
            :rules="{ required: true, regex: PHONE_REGEX }"
            v-slot="{ errors }"
          >
            <esmp-input
              label="Мобильный телефон в формате +7ХХХХХХХХХХ"
              :error-message="errors[0]"
              :value="commonData.phone"
              @input="
                setCommonDataField({
                  field: 'phone',
                  value: $event,
                })
              "
            />
          </validation-provider>
        </div>
        <div class="common-data__cell">
          <validation-provider
            name="Цель командировки"
            rules="required"
            v-slot="{ errors }"
          >
            <esmp-select
              placeholder="Цель командировки"
              filterable
              clearable
              :error-message="errors[0]"
              :value="commonData.target"
              @on-select="setArticle"
              @input="
                setCommonDataField({
                  field: 'target',
                  value: $event,
                })
              "
            >
              <esmp-select-option
                v-for="item in targets"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </esmp-select-option>
            </esmp-select>
          </validation-provider>
        </div>
      </div>

      <div
        class="common-data__row"
        v-if="localTarget && localTarget.description"
      >
        <div class="common-data__cell">
          <validation-provider
            name="Детали командировки"
            rules="required"
            v-slot="{ errors }"
          >
            <esmp-input
              :label="targetDetailsPlaceholder"
              :error-message="errors[0]"
              :value="commonData.details"
              @input="
                setCommonDataField({
                  field: 'details',
                  value: $event,
                })
              "
            />
          </validation-provider>
        </div>
      </div>

      <div class="common-data__row">
        <div class="common-data__cell">
          <validation-provider
            name="ЦФО-потребителя"
            rules="required"
            v-slot="{ errors }"
          >
            <esmp-select
              placeholder="ЦФО-потребителя"
              disabled
              :error-message="errors[0]"
              @on-select="findBusinessProcesses"
              :value="commonData.cfd"
              @input="
                setCommonDataField({
                  field: 'cfd',
                  value: $event,
                })
              "
            >
              <esmp-select-option
                v-for="item in cfdList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </esmp-select-option>
            </esmp-select>
          </validation-provider>
        </div>
        <div class="common-data__cell">
          <validation-provider
            name="Статья"
            rules="required"
            v-slot="{ errors }"
          >
            <esmp-select
              placeholder="Статья"
              filterable
              clearable
              :error-message="errors[0]"
              @on-select="findBusinessProcesses"
              :value="commonData.articles"
              @input="
                setCommonDataField({
                  field: 'articles',
                  value: $event,
                })
              "
            >
              <esmp-select-option
                v-for="item in articles"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </esmp-select-option>
            </esmp-select>
          </validation-provider>
        </div>
      </div>

      <div
        class="common-data__row"
        v-if="checkFieldDependence(localArticles, articles[3])"
      >
        <div class="common-data__cell">
          <validation-provider
            name="Проект"
            rules="required"
            v-slot="{ errors }"
          >
            <investment-project
              :value="commonData.project"
              :error-message="errors[0]"
              @input="
                setCommonDataField({
                  field: 'project',
                  value: $event,
                })
              "
            />
            <div v-if="errors[0]" class="business-trip__error">
              {{ errors[0] }}
            </div>
          </validation-provider>
        </div>
      </div>

      <div class="common-data__row">
        <div class="common-data__cell business-trip__search">
          <validation-provider
            name="Руководитель подразделения"
            rules="required"
            v-slot="{ errors }"
          >
            <user-search
              placeholder="Руководитель подразделения"
              :is-valid="true"
              :error-message="errors[0]"
              :value="commonData.head"
              @input="
                setCommonDataField({
                  field: 'head',
                  value: $event,
                })
              "
            />
          </validation-provider>
        </div>
        <div class="common-data__cell">
          <template
            v-if="checkFieldDependence(localArticles, articles[3], true)"
          >
            <validation-provider
              name="Бизнес-процесс"
              rules="required"
              v-slot="{ errors }"
            >
              <esmp-select
                placeholder="Бизнес-процесс"
                filterable
                clearable
                :error-message="errors[0]"
                @on-select="getCodeProgramsList"
                :value="commonData.businessProcess"
                @input="
                  setCommonDataField({
                    field: 'businessProcess',
                    value: $event,
                  })
                "
              >
                <esmp-select-option
                  v-for="item in businessProcessesList"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </esmp-select-option>
              </esmp-select>
            </validation-provider>
          </template>
        </div>
      </div>

      <div
        v-if="checkFieldDependence(localArticles, articles[3], true)"
        class="common-data__row"
      >
        <div class="common-data__cell">
          <esmp-checkbox
            :value="commonData.hasCodeProgram"
            @input="
              setCommonDataField({
                field: 'hasCodeProgram',
                value: $event,
              })
            "
          >
            Указать код программы
          </esmp-checkbox>
        </div>
        <div class="common-data__cell">
          <template v-if="commonData.hasCodeProgram">
            <validation-provider
              name="Код программы"
              rules="required"
              v-slot="{ errors }"
            >
              <esmp-select
                placeholder="Код программы"
                filterable
                clearable
                :error-message="errors[0]"
                @on-select="getCodeProgramsList"
                :value="commonData.codeProgram"
                @input="
                  setCommonDataField({
                    field: 'codeProgram',
                    value: $event,
                  })
                "
              >
                <esmp-select-option
                  v-for="item in (codePrograms || [])"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </esmp-select-option>
              </esmp-select>
            </validation-provider>
          </template>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

import { PHONE_REGEX, ARTICLES } from '@/constants/business-trip2';

import UserSearch from '../components/UserSearch.vue';
import InvestmentProject from '../components/InvestmentProject.vue';
import checkFieldDependence from '../helpers/checkFieldDependence';

export default {
  name: 'CommonData',
  components: {
    UserSearch,
    InvestmentProject,
  },
  props: {
    editableData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      PHONE_REGEX,
    };
  },
  computed: {
    ...mapState('businessTrip2', [
      'isEdit',
      'activeUser',
      'commonData',
      'codePrograms',
    ]),
    ...mapGetters('businessTrip2', [
      'targets',
      'cfd',
      'articles',
      'businessProcesses',
    ]),
    localActiveUser: {
      get() {
        return this.activeUser;
      },
      set(value) {
        this.setActiveUser(value);
      },
    },
    localArticles() {
      if (this.commonData.articles) {
        return this.articles.find((t) => t.id === this.commonData.articles);
      }
      return null;
    },
    cfdList() {
      if (this.cfd && this.cfd.length) {
        return this.cfd.map((item) => ({
          code: item.code,
          id: item.id,
          name: `${item.code} ${item.name}`,
        }));
      }

      return [];
    },
    localCfd() {
      if (this.commonData.cfd) {
        return this.cfdList.find((t) => t.id === this.commonData.cfd);
      }
      return null;
    },
    localTarget() {
      if (this.commonData.target) {
        return this.targets.find((t) => t.id === this.commonData.target);
      }
      return null;
    },
    targetDetailsPlaceholder() {
      if (this.localTarget) {
        const value = `${this.localTarget.description}`;
        return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
      }

      return 'Детали командировки';
    },
    localBusinessProcess() {
      if (this.commonData.businessProcess) {
        return this.businessProcessesList.find((t) => t.id === this.commonData.businessProcess);
      }
      return null;
    },
    businessProcessesList() {
      if (this.businessProcesses && this.businessProcesses.length) {
        return this.businessProcesses.map((item) => ({
          id: item.id,
          name: `${item.id} ${item.name}`,
        }));
      }

      return [];
    },
  },
  methods: {
    ...mapActions('businessTrip2', [
      'setActiveUser',
      'setCommonDataField',
      'getFreeFund',
      'getBusinessProcesses',
      'getCodePrograms',
    ]),
    checkFieldDependence,
    setArticle(target) {
      let article = null;
      if (target?.name === ARTICLES.education.targetName) {
        article = this.articles.find(
          (a) => a.id === ARTICLES.education.articleId,
        );
      } else {
        article = this.articles.find(
          (a) => a.id === ARTICLES.jobAssignment.articleId,
        );
      }
      if (article) {
        this.setCommonDataField({
          field: 'articles',
          value: article.id,
        });
        this.findBusinessProcesses();
      }
    },
    setCFD() {
      if (
        this.cfdList?.length
        && (this.localActiveUser || this.isEdit)
      ) {
        const cfoCode = this.isEdit && !this.localCfd
          ? this.editableData.cfoConsumer
          : this.localActiveUser.cfoCode;

        const userCfd = this.cfdList.find((item) => item.code === cfoCode);

        if (userCfd) {
          this.setCommonDataField({
            field: 'cfd',
            value: userCfd.id,
          });
        }
      }
    },
    async getCodeProgramsList() {
      await this.$nextTick();

      const budgetArticle = this.localArticles
        ? this.localArticles.id
        : null;
      const budgetUnitCode = this.localActiveUser
        ? this.localActiveUser.budgetUnitCode
        : null;
      const consumerCFO = this.localCfd
        ? this.localCfd.code
        : null;
      const businessProcessId = this.localBusinessProcess
        ? this.localBusinessProcess.id
        : null;

      if (
        budgetArticle
        && budgetUnitCode
        && consumerCFO
        && businessProcessId
      ) {
        this.getCodePrograms({
          budgetArticle,
          budgetUnitCode,
          consumerCFO,
          businessProcessId,
        });
      }
    },
    async findBusinessProcesses() {
      await this.$nextTick();

      const assignmentId = this.localActiveUser
        ? this.localActiveUser.assignmentId
        : null;
      const budgetArticle = this.localArticles
        ? this.localArticles.id
        : null;
      const budgetUnitCode = this.localActiveUser
        ? this.localActiveUser.budgetUnitCode
        : null;
      const consumerCFO = this.localCfd
        ? this.localCfd.code
        : null;

      if (budgetArticle && budgetUnitCode && consumerCFO) {
        this.getFreeFund({
          budgetArticle,
          budgetUnit: budgetUnitCode,
          consumerCFO,
        });

        if (assignmentId) {
          this.getBusinessProcesses({
            assignmentId,
            budgetArticle,
            budgetUnitCode,
            consumerCFO,
          });
        }
      }
    },
    validate() {
      return this.$refs.common.validate();
    },
  },
  watch: {
    activeUser(val) {
      if (val) {
        let value = null;

        if (val.manager && val.managerTabNumber) {
          value = {
            tabNumber: `${val.managerTabNumber}`,
            assignmentId: '',
            fullName: `${val.manager}`,
          };
        }

        this.setCommonDataField({
          field: 'head',
          value,
        });
      }

      this.findBusinessProcesses();
      this.setCFD();
    },
    cfd() {
      this.setCFD();
    },
    businessProcessesList(val) {
      if (val?.length === 1) {
        this.setCommonDataField({
          field: 'businessProcess',
          value: val[0].id,
        });
      } else if (val?.length) {
        if (this.localBusinessProcess) {
          const index = val.findIndex(
            (process) => process.id === this.localBusinessProcess.id,
          );
          if (index === -1) {
            this.setCommonDataField({
              field: 'businessProcess',
              value: null,
            });
          }
        } else if (this.isEdit && !this.localBusinessProcess) {
          const businessProcess = val.find(
            (process) => process.id === this.editableData.businessProcessCode,
          );
          if (businessProcess) {
            this.setCommonDataField({
              field: 'businessProcess',
              value: businessProcess.id,
            });
          }
        }
      }

      this.getCodeProgramsList();
    },
  },
};
</script>

<style scoped lang="scss">
.common-data {
  width: 100%;
  background-color: $color-white;
  padding: 20px 16px;
  border-radius: $base-border-radius;
  &__row {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & + & {
      margin-top: 16px;
    }
  }

  &__cell {
    width: calc(50% - 10px);
    &:first-child:last-child {
      width: 100%;
    }
  }
}
</style>
