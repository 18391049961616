<template>
  <div class="expenses-block">
    <validation-observer ref="expenses">
      <div class="business-trip__title">
        Общие сведения по командировке:
      </div>

      <div class="business-trip__expenses-row">
        <div class="business-trip__expenses-col">
          <div class="business-trip__expenses-fieldset">
            <div
              class="business-trip__expenses-field business-trip__expenses-field--big"
            >
              <validation-provider
                name="Город назначения"
                rules="required|city"
                v-slot="{ errors }"
              >
                <destination-field
                  :key="
                    `city_${expenses.cities[0].id}`
                  "
                  placeholder="Город назначения"
                  :is-valid="true"
                  :hide-double="false"
                  @input="updateExpensesCity({
                    city: $event,
                    index: 0,
                  })"
                  :value="expenses.cities[0]"
                />
                <div v-if="errors[0]" class="business-trip__error">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </div>
        </div>

        <div class="business-trip__expenses-col">
          <div class="business-trip__expenses-fieldset">
            <div class="business-trip__expenses-field">
              <validation-provider
                name="Место назначения"
                rules="required"
                v-slot="{ errors }"
              >
                <esmp-select
                  v-if="organizations && organizations.length"
                  placeholder="Место назначения"
                  filterable
                  clearable
                  :error-message="errors[0]"
                  :value="expenses.organization"
                  @input="
                    updateExpenses({
                      field: 'organization',
                      value: $event,
                    })
                  "
                >
                  <esmp-select-option
                    v-for="item in organizations"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </esmp-select-option>
                </esmp-select>
              </validation-provider>
            </div>
            <div
              class="business-trip__expenses-field"
              v-if="checkFieldDependence(localOrganization, organizations[1])"
            >
              <validation-provider
                name="Другая организация"
                rules="required"
                v-slot="{ errors }"
              >
                <esmp-input
                  ref="otherOrganization"
                  label="Другая организация"
                  :error-message="errors[0]"
                  :value="expenses.otherOrganization"
                  @input="
                    updateExpenses({
                      field: 'otherOrganization',
                      value: $event,
                    })
                  "
                />
              </validation-provider>
            </div>
          </div>
        </div>

        <div class="business-trip__expenses-col">
          <div class="business-trip__expenses-fieldset">
            <div
              class="business-trip__expenses-fieldset-title"
            >
              Срок командировки {{ commonTripDays }}
              {{
                pluralization(commonTripDays, [
                  daysWords.one,
                  daysWords.few,
                  daysWords.many,
                ])
              }}:
            </div>
            <div class="business-trip__expenses-fieldset-inner">
              <div class="business-trip__expenses-field">
                <validation-provider
                  name="Туда"
                  :rules="{ required: true, datePeriod: { endDay: expenses.commonDayEnd } }"
                  v-slot="{ errors }"
                >
                  <esmp-datepicker-adaptive
                    placeholder="Туда"
                    :disabled-dates="disabledCommonDayStart"
                    @change="
                      setExpensesDate(
                        'commonDayStart',
                        $event,
                        'setStartFirstChange',
                      )
                    "
                    :value="expenses.commonDayStart"
                  />
                  <div v-if="errors[0]" class="business-trip__error">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>
              <div class="business-trip__expenses-field">
                <validation-provider
                  name="Обратно"
                  :rules="{ required: true, datePeriod: { startDay: expenses.commonDayStart } }"
                  v-slot="{ errors }"
                >
                  <esmp-datepicker-adaptive
                    placeholder="Обратно"
                    :disabled-dates="disabledCommonDayEnd"
                    @change="
                      setExpensesDate(
                        'commonDayEnd',
                        $event,
                        'setEndFirstChange',
                      )
                    "
                    :value="expenses.commonDayEnd"
                  />
                  <div v-if="errors[0]" class="business-trip__error">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>

        <div class="business-trip__expenses-col">
          <div class="business-trip__daily-allowance">
            Суточные: <span>{{ dailyAllowances }}</span>
            {{
              pluralization(dailyAllowances, [
                rubWords.one,
                rubWords.few,
                rubWords.many,
              ])
            }}
          </div>
        </div>
      </div>
      <div
        class="business-trip__expenses-row"
        v-for="(destination, index) in expenses.cities"
        :key="destination.id"
      >
        <template v-if="index !== 0">
          <div class="business-trip__expenses-col">
            <div class="business-trip__expenses-fieldset">
              <div
                class="business-trip__expenses-field business-trip__expenses-field--big"
              >
                <validation-provider
                  :name="`Город назначения ${(index + 1)}`"
                  rules="required|city"
                  v-slot="{ errors }"
                >
                  <destination-field
                    :key="
                      `city_${expenses.cities[index].id}`
                    "
                    placeholder="Город назначения"
                    :is-valid="true"
                    :hide-double="false"
                    @input="updateExpensesCity({
                      city: $event,
                      index,
                    })"
                    :value="expenses.cities[index]"
                  />

                  <div v-if="errors[0]" class="business-trip__error">
                    {{ errors[0] }}
                  </div>
                </validation-provider>
              </div>
            </div>
          </div>
          <div class="business-trip__expenses-col">
            <div class="business-trip__expenses-fieldset">
              <action-btn
                :is-remove="true"
                @click="removeExpensesCity(index)"
              >
                Удалить город
              </action-btn>
            </div>
          </div>
        </template>
      </div>
      <div class="business-trip__expenses-row">
        <div class="business-trip__expenses-col">
          <div class="business-trip__expenses-fieldset">
            <action-btn
              @click="addExpensesCity()"
            >
              Добавить город назначения
            </action-btn>
          </div>
        </div>
      </div>
    </validation-observer>

    <esmp-modal
      v-model="showModalChangeDate"
      title="Изменения дат"
      :width="800"
      cancel-text="Нет"
      ok-text="Да"
      @on-ok="changeDateHandler"
    >
      <p>Скорректировать даты проезда и проживания?</p>
    </esmp-modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import dayjs from 'dayjs';

import pluralization from '@/utils/pluralization';
import { BUSINESS_TRIP_WORD_CASES } from '@/constants/business-trip2';

import DestinationField from '../components/destination/DestinationField.vue';
import checkFieldDependence from '../helpers/checkFieldDependence';
import ActionBtn from '../components/ActionBtn.vue';

export default {
  name: 'ExpensesBlock',
  components: {
    DestinationField,
    ActionBtn,
  },
  props: {
    editableData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // словари
      daysWords: Object.freeze(BUSINESS_TRIP_WORD_CASES.dayDuration),
      rubWords: Object.freeze(BUSINESS_TRIP_WORD_CASES.rub),

      showModalChangeDate: false,
      changeDateFunc: null,
    };
  },
  computed: {
    ...mapState('businessTrip2', [
      'isEdit',
      'expenses',
      'transit',
      'accommodation',
    ]),
    ...mapGetters('businessTrip2', [
      'organizations',
      'commonTripDays',
      'dailyAllowances',
    ]),
    localOrganization() {
      if (this.expenses.organization) {
        return this.organizations.find((t) => t.id === this.expenses.organization);
      }
      return null;
    },
    // валидация дат
    disabledCommonDayStart() {
      return (date) => {
        if (this.isEdit || !this.expenses.commonDayEnd) {
          return false;
        }
        return dayjs(date).isAfter(dayjs(this.expenses.commonDayEnd));
      };
    },
    disabledCommonDayEnd() {
      return (date) => {
        if (this.isEdit || !this.expenses.commonDayStart) {
          return false;
        }
        return dayjs(date).isBefore(dayjs(this.expenses.commonDayStart));
      };
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('businessTrip2', [
      'setCityChange',
      'updateExpensesCity',
      'updateExpenses',
      'setStartFirstChange',
      'setEndFirstChange',
      'addExpensesCity',
      'removeExpensesCity',
    ]),

    checkFieldDependence,
    pluralization,

    setExpensesDate(field, value, func) {
      this.updateExpenses({ field, value });
      if (!this.isEdit) {
        let clearAccommodation = false;
        if (this.expenses.commonDayStart
          && this.expenses.commonDayEnd
          && this.expenses.commonDayStart === this.expenses.commonDayEnd) {
          clearAccommodation = true;
        }
        this[func](clearAccommodation);
      } else if (this.transit.length || this.accommodation.length) {
        this.changeDateFunc = func;
        this.showModalChangeDate = true;
      }
    },
    changeDateHandler() {
      this[this.changeDateFunc]();
    },
  },
};
</script>
