import dayjs from 'dayjs';

const getDate = (dateStr) => {
  if (dateStr.includes('.')) {
    return dayjs(dateStr, 'DD.MM.YYYY').toDate();
  }

  return dayjs(dateStr).toDate();
};

export default getDate;
