var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expenses-block"},[_c('validation-observer',{ref:"expenses"},[_c('div',{staticClass:"business-trip__title"},[_vm._v(" Общие сведения по командировке: ")]),_c('div',{staticClass:"business-trip__expenses-row"},[_c('div',{staticClass:"business-trip__expenses-col"},[_c('div',{staticClass:"business-trip__expenses-fieldset"},[_c('div',{staticClass:"business-trip__expenses-field business-trip__expenses-field--big"},[_c('validation-provider',{attrs:{"name":"Город назначения","rules":"required|city"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('destination-field',{key:`city_${_vm.expenses.cities[0].id}`,attrs:{"placeholder":"Город назначения","is-valid":true,"hide-double":false,"value":_vm.expenses.cities[0]},on:{"input":function($event){return _vm.updateExpensesCity({
                  city: $event,
                  index: 0,
                })}}}),(errors[0])?_c('div',{staticClass:"business-trip__error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)])]),_c('div',{staticClass:"business-trip__expenses-col"},[_c('div',{staticClass:"business-trip__expenses-fieldset"},[_c('div',{staticClass:"business-trip__expenses-field"},[_c('validation-provider',{attrs:{"name":"Место назначения","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [(_vm.organizations && _vm.organizations.length)?_c('esmp-select',{attrs:{"placeholder":"Место назначения","filterable":"","clearable":"","error-message":errors[0],"value":_vm.expenses.organization},on:{"input":function($event){return _vm.updateExpenses({
                    field: 'organization',
                    value: $event,
                  })}}},_vm._l((_vm.organizations),function(item){return _c('esmp-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1):_vm._e()]}}])})],1),(_vm.checkFieldDependence(_vm.localOrganization, _vm.organizations[1]))?_c('div',{staticClass:"business-trip__expenses-field"},[_c('validation-provider',{attrs:{"name":"Другая организация","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('esmp-input',{ref:"otherOrganization",attrs:{"label":"Другая организация","error-message":errors[0],"value":_vm.expenses.otherOrganization},on:{"input":function($event){return _vm.updateExpenses({
                    field: 'otherOrganization',
                    value: $event,
                  })}}})]}}],null,false,284826932)})],1):_vm._e()])]),_c('div',{staticClass:"business-trip__expenses-col"},[_c('div',{staticClass:"business-trip__expenses-fieldset"},[_c('div',{staticClass:"business-trip__expenses-fieldset-title"},[_vm._v(" Срок командировки "+_vm._s(_vm.commonTripDays)+" "+_vm._s(_vm.pluralization(_vm.commonTripDays, [ _vm.daysWords.one, _vm.daysWords.few, _vm.daysWords.many, ]))+": ")]),_c('div',{staticClass:"business-trip__expenses-fieldset-inner"},[_c('div',{staticClass:"business-trip__expenses-field"},[_c('validation-provider',{attrs:{"name":"Туда","rules":{ required: true, datePeriod: { endDay: _vm.expenses.commonDayEnd } }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('esmp-datepicker-adaptive',{attrs:{"placeholder":"Туда","disabled-dates":_vm.disabledCommonDayStart,"value":_vm.expenses.commonDayStart},on:{"change":function($event){return _vm.setExpensesDate(
                      'commonDayStart',
                      $event,
                      'setStartFirstChange',
                    )}}}),(errors[0])?_c('div',{staticClass:"business-trip__error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"business-trip__expenses-field"},[_c('validation-provider',{attrs:{"name":"Обратно","rules":{ required: true, datePeriod: { startDay: _vm.expenses.commonDayStart } }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('esmp-datepicker-adaptive',{attrs:{"placeholder":"Обратно","disabled-dates":_vm.disabledCommonDayEnd,"value":_vm.expenses.commonDayEnd},on:{"change":function($event){return _vm.setExpensesDate(
                      'commonDayEnd',
                      $event,
                      'setEndFirstChange',
                    )}}}),(errors[0])?_c('div',{staticClass:"business-trip__error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)])])]),_c('div',{staticClass:"business-trip__expenses-col"},[_c('div',{staticClass:"business-trip__daily-allowance"},[_vm._v(" Суточные: "),_c('span',[_vm._v(_vm._s(_vm.dailyAllowances))]),_vm._v(" "+_vm._s(_vm.pluralization(_vm.dailyAllowances, [ _vm.rubWords.one, _vm.rubWords.few, _vm.rubWords.many, ]))+" ")])])]),_vm._l((_vm.expenses.cities),function(destination,index){return _c('div',{key:destination.id,staticClass:"business-trip__expenses-row"},[(index !== 0)?[_c('div',{staticClass:"business-trip__expenses-col"},[_c('div',{staticClass:"business-trip__expenses-fieldset"},[_c('div',{staticClass:"business-trip__expenses-field business-trip__expenses-field--big"},[_c('validation-provider',{attrs:{"name":`Город назначения ${(index + 1)}`,"rules":"required|city"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('destination-field',{key:`city_${_vm.expenses.cities[index].id}`,attrs:{"placeholder":"Город назначения","is-valid":true,"hide-double":false,"value":_vm.expenses.cities[index]},on:{"input":function($event){return _vm.updateExpensesCity({
                    city: $event,
                    index,
                  })}}}),(errors[0])?_c('div',{staticClass:"business-trip__error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)])]),_c('div',{staticClass:"business-trip__expenses-col"},[_c('div',{staticClass:"business-trip__expenses-fieldset"},[_c('action-btn',{attrs:{"is-remove":true},on:{"click":function($event){return _vm.removeExpensesCity(index)}}},[_vm._v(" Удалить город ")])],1)])]:_vm._e()],2)}),_c('div',{staticClass:"business-trip__expenses-row"},[_c('div',{staticClass:"business-trip__expenses-col"},[_c('div',{staticClass:"business-trip__expenses-fieldset"},[_c('action-btn',{on:{"click":function($event){return _vm.addExpensesCity()}}},[_vm._v(" Добавить город назначения ")])],1)])])],2),_c('esmp-modal',{attrs:{"title":"Изменения дат","width":800,"cancel-text":"Нет","ok-text":"Да"},on:{"on-ok":_vm.changeDateHandler},model:{value:(_vm.showModalChangeDate),callback:function ($$v) {_vm.showModalChangeDate=$$v},expression:"showModalChangeDate"}},[_c('p',[_vm._v("Скорректировать даты проезда и проживания?")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }