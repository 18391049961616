<template>
  <div class="business-trip">
    <stepper :steps="steps" :active-step="activeStep">
      <div slot="commonData">
        <common-data
          ref="step1"
          :editable-data="editableData"
        />
      </div>

      <div slot="expenses">
        <template v-if="activeStep === 2">
          <div class="business-trip__expenses">
            <validation-observer ref="step2">
              <div class="business-trip__expenses-block">
                <expenses-block
                  :editable-data="editableData"
                />
              </div>

              <div class="business-trip__expenses-block">
                <div class="business-trip__title">
                  Проезд:
                </div>
                <transit-block
                  v-for="(item, index) in transit"
                  :fields="item"
                  :is-vip="localActiveUser ? localActiveUser.isVIP : false"
                  :current-index="index"
                  :key="item.id"
                />

                <div class="business-trip__expenses-row">
                  <div
                    class="business-trip__expenses-col business-trip__expenses-col--big-offset"
                  >
                    <action-btn
                      @click="addTransit(true)"
                    >
                      Добавить маршрут
                    </action-btn>
                  </div>
                  <div
                    class="business-trip__expenses-col business-trip__expenses-col--big-offset"
                  >
                    <div class="business-trip__footnote">
                      В случае раннего отправления\прибытия вы можете
                      воспользоваться услугой заказа автотранспорта, доступной по
                      <esmp-link
                        :to="R12_LINK"
                        target="_blank"
                      >
                        ссылке
                      </esmp-link>
                    </div>
                  </div>
                  <div
                    class="business-trip__expenses-col business-trip__expenses-col--big-offset"
                  >
                    <esmp-checkbox
                      :value="expenses.isNeedHelp"
                      @input="
                        updateExpenses({
                          field: 'isNeedHelp',
                          value: $event,
                        })
                      "
                    >
                      Нужна помощь в подборе билета
                    </esmp-checkbox>
                  </div>
                </div>
              </div>

              <div class="business-trip__expenses-block">
                <div class="business-trip__title">
                  Проживание:
                </div>
                <accommodation-block
                  v-for="(item, index) in accommodation"
                  :fields="item"
                  :is-edit="isEdit"
                  :key="item.id"
                  :current-index="index"
                />

                <div class="business-trip__expenses-row">
                  <div
                    class="business-trip__expenses-col business-trip__expenses-col--big-offset"
                  >
                    <action-btn
                      @click="addAccommodation"
                    >
                      Добавить отель
                    </action-btn>
                  </div>

                  <div
                    class="business-trip__expenses-col business-trip__expenses-col--big-offset"
                  >
                    <esmp-checkbox
                      :value="expenses.groupBooking"
                      @input="
                        updateExpenses({ field: 'groupBooking', value: $event })
                      "
                    >
                      Групповое бронирование
                    </esmp-checkbox>
                  </div>
                </div>

                <div class="business-trip__expenses-row">
                  <div
                    class="business-trip__expenses-col business-trip__expenses-col--big-offset"
                  >
                    <esmp-checkbox
                      :value="expenses.isSelfBooking"
                      @input="
                        updateExpenses({ field: 'isSelfBooking', value: $event })
                      "
                    >
                      Необходим аванс?
                    </esmp-checkbox>
                  </div>
                </div>
                <div
                  class="business-trip__expenses-row"
                  v-if="expenses.isSelfBooking"
                >
                  <div class="business-trip__expenses-col">
                    <div class="business-trip__expenses-fieldset">
                      <div class="business-trip__expenses-field">
                        <validation-provider
                          name="Сумма аванса"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <esmp-input
                            :error-message="errors[0]"
                            label="Сумма аванса"
                            :value="expenses.avansAmount"
                            @input="
                              updateExpenses({
                                field: 'avansAmount',
                                value: $event,
                              })
                            "
                          />
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                  <div class="business-trip__expenses-col">
                    <div class="business-trip__expenses-fieldset">
                      <div
                        class="business-trip__expenses-field business-trip__expenses-field--big"
                      >
                        <validation-provider
                          name="Комментарий к авансу"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <esmp-select
                            placeholder="Комментарий к авансу"
                            filterable
                            clearable
                            :value="expenses.avansAmountComment ? expenses.avansAmountComment.id : null"
                            @input="
                              updateExpenses({
                                field: 'avansAmountComment',
                                value: $event,
                                list: avansComments,
                              })
                            "
                          >
                            <esmp-select-option
                              v-for="item in avansComments"
                              :key="item.id"
                              :value="item.id"
                            >
                              {{ item.name }}
                            </esmp-select-option>
                          </esmp-select>
                          <div v-if="errors[0]" class="business-trip__error">
                            {{ errors[0] }}
                          </div>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="business-trip__result">
                <div class="business-trip__result-inner">
                  <div
                    class="business-trip__result-name"
                  >
                    Общая стоимость командировки:
                  </div>
                  <div
                    class="business-trip__result-value"
                  >
                    {{ commonCostText }} рублей
                  </div>
                  <template v-if="freeFund">
                    <div
                      class="business-trip__result-name"
                    >
                      Свободные фонды:
                    </div>
                    <div
                      class="business-trip__result-value"
                    >
                      {{ freeFund }} рублей
                    </div>
                  </template>
                </div>
              </div>
            </validation-observer>
          </div>
        </template>
      </div>
    </stepper>

    <div
      :class="['page__action-buttons', { 'page__action-buttons--wide': isCollapsedMenu}]"
    >
      <esmp-button
        v-if="activeStep === 1"
        variant="primary"
        size="large"
        @click="setStep(2)"
      >
        Далее
      </esmp-button>
      <template v-else>
        <esmp-button
          class="page__action-button"
          view="outline"
          size="large"
          @click="setStep(1)"
        >
          Назад
        </esmp-button>
        <template v-if="isEdit">
          <esmp-button
            class="page__action-button"
            view="outline"
            size="large"
            @click="cancelEdit"
          >
            Отменить изменения
          </esmp-button>
          <esmp-button
            class="page__action-button"
            view="primary"
            size="large"
            @disabled-click="validateFields"
            @click="saveEdit"
          >
            Сохранить изменения
          </esmp-button>
        </template>
        <esmp-button
          v-else=""
          class="page__action-button"
          view="primary"
          size="large"
          @click="sendBusinessTrip"
        >
          Отправить на согласование
        </esmp-button>
      </template>
    </div>

    <esmp-modal
      v-model="showModals.infoModal"
      :title="showModals.title"
      :width="800"
      cancel-text="Закрыть"
      class-name="info-modal"
    >
      <p v-html="showModals.msg" />
    </esmp-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isBoolean from 'lodash/isBoolean';
import cloneDeep from 'lodash/cloneDeep';

import {
  BUSINESS_TRIP_TABS,
  CHECKLIST_DATA,
  RTK_NAMES,
  EMAIL_REGEX,
  R12_LINK,
} from '@/constants/business-trip2';

import CommonData from './blocks/CommonData.vue';
import ExpensesBlock from './blocks/ExpensesBlock.vue';
import TransitBlock from './blocks/TransitBlock.vue';
import AccommodationBlock from './blocks/AccommodationBlock.vue';
import Stepper from './components/Stepper.vue';
import ActionBtn from './components/ActionBtn.vue';

import checkFieldDependence from './helpers/checkFieldDependence';
import convertDataArrayToObj from './helpers/convertDataArrayToObj';
import getDestinationCity from './helpers/getDestinationCity';
import pushValueFunc from './helpers/pushValueFunc';
import getOldValueFunc from './helpers/getOldValueFunc';
import pushTicketsFunc from './helpers/pushTicketsFunc';
import pushEmptyTicketsFunc from './helpers/pushEmptyTicketsFunc';
import pushHotelsFunc from './helpers/pushHotelsFunc';
import pushEmptyHotelsFunc from './helpers/pushEmptyHotelsFunc';
import destinationPlacesItem from './helpers/destinationPlacesItem';
import addPlacesFunc from './helpers/addPlacesFunc';
import getDate from './helpers/getDate';
import getDestinationFunc from './helpers/getDestinationFunc';
import setCityFunc from './helpers/setCityFunc';
import getNewDestination from './helpers/getNewDestination';
import checkDestinationValue from './helpers/checkDestinationValue';
import setTransitFunc from './helpers/setTransitFunc';
import setHotelsFunc from './helpers/setHotelsFunc';

export default {
  name: 'BusinessTrip',
  props: {
    isEdit: Boolean,
    data: {
      type: Array,
      default: undefined,
    },
  },
  components: {
    Stepper,
    CommonData,
    ExpensesBlock,
    TransitBlock,
    AccommodationBlock,
    ActionBtn,
  },
  data() {
    return {
      isSending: false,

      steps: Object.freeze(BUSINESS_TRIP_TABS),
      activeStep: 1,

      R12_LINK,

      showModals: {
        title: '',
        msg: '',
        infoModal: false,
      },
    };
  },
  computed: {
    ...mapGetters('user', ['selectedOrLoggedInUser']),
    ...mapState('user', ['loggedInUser']),
    ...mapState('sidebar', ['isCollapsedMenu']),
    ...mapState('businessTrip2', [
      'activeUser',
      'initiatorUser',
      'usetCity',
      'limits',
      // delete ?
      'expenses',
      // delete ?
      'transit',
      'accommodation',
    ]),
    ...mapGetters('businessTrip2', [
      // delete ?
      'territorialZones',
      'avansComments',
      // delete ?
      'organizations',
      'freeFund',
      'dailyAllowances',
      'transitAllowances',
      'accommodationAllowances',
      'timeOfDay',
    ]),
    localActiveUser: {
      get() {
        return this.activeUser;
      },
      set(value) {
        this.setActiveUser(value);
      },
    },
    localInitiatorUser: {
      get() {
        return this.initiatorUser;
      },
      set(value) {
        this.setInitiatorUser(value);
      },
    },
    localUserCity: {
      get() {
        return this.userCity;
      },
      set(value) {
        this.setUserCity(value);
      },
    },

    // select values
    // delete ?
    expensesOrganization() {
      if (this.expenses.organization) {
        return this.organizations.find((t) => t.id === this.expenses.organization);
      }
      return null;
    },

    commonCost() {
      const value = this.dailyAllowances
        + this.transitAllowances
        + this.accommodationAllowances;
      return Math.round(value * 100) / 100;
    },
    commonCostText() {
      return Math.round(this.commonCost).toLocaleString();
    },

    // преобразованные данных, пришедшие на редактирование
    editableData() {
      return convertDataArrayToObj(this.data);
    },
  },
  methods: {
    ...mapActions('businessTrip2', [
      'setIsEdit',
      'setActiveUser',
      'setInitiatorUser',
      'setUserCity',
      'getTargets',
      'getCfd',
      'getLimits',
      'getConstants',
      'addExpensesCity',
      'updateExpensesCity',
      'updateExpenses',
      'clearExpenses',
      'addTransit',
      'updateTransit',
      'clearTransit',
      'getRegion',
      'getArea',
      'getCommonData',
      'addAccommodation',
      'updateAccommodation',
      'clearState',
      'setCommonDataField',
    ]),
    ...mapActions('system', ['setLoading']),

    checkFieldDependence,

    toggleModal(payload) {
      if (isBoolean(this.showModals[payload.modalName])) {
        this.showModals[payload.modalName] = payload.newValue;
      }
    },

    async setStep(step) {
      const isValid = await this.validateStep();
      if (!isValid) return;

      this.isLoadStep = true;
      this.activeStep = step;
      if (step === 1) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
      setTimeout(() => {
        this.isLoadStep = false;
        this.$refs[`step${step}`].reset();
      }, 10);
    },

    validateStep() {
      if (this.activeStep === 1) {
        return this.$refs.step1.validate();
      }

      return true;
    },
    async validateFields() {
      const step1Valid = await this.$refs.step1.validate();
      const step2Valid = await this.$refs.step2.validate();

      return step1Valid && step2Valid;
    },

    // формирование чеклиста для отправки
    async getBusinessTrip() {
      // возращаемое значение
      const valueArr = [];

      const tickets = [];
      const hotels = [];

      const storeCommonData = await this.getCommonData();

      const getOldValue = getOldValueFunc(this.isEdit, this.editableData);
      const pushValue = pushValueFunc(valueArr, this.isEdit, this.editableData);
      const pushTickets = pushTicketsFunc(tickets, pushValue, getOldValue);
      const pushEmptyTickets = pushEmptyTicketsFunc(pushValue, getOldValue);
      const pushHotels = pushHotelsFunc(hotels, pushValue, getOldValue);
      const pushEmptyHotels = pushEmptyHotelsFunc(pushValue, getOldValue);
      const addPlaces = addPlacesFunc(this.expenses, pushValue, getOldValue);

      // заполнение valueArr данными
      // порядок важен
      pushValue(
        'employeeNumber',
        this.localActiveUser.tabNumber,
        this.localActiveUser.fullName,
      );
      pushValue('type', 'Плановая');
      pushValue('userPosition', this.localActiveUser.jobFullName);
      pushValue('userBlock', this.localActiveUser.block);
      pushValue('userOrganization', this.localActiveUser.organization);
      pushValue('userDepartment', this.localActiveUser.department);
      pushValue(
        'userEmail',
        this.localActiveUser.email || this.localActiveUser.assignmentNumber,
      );
      const isVIPGrade = this.localActiveUser.isVIPGrade ? '1' : '0';
      pushValue('isVIPGrade', this.localActiveUser.isVIPGrade, isVIPGrade);
      pushValue(
        'initiatorEmployeeNumber',
        this.loggedInUser.tabNumber || this.loggedInUser.employeeNumber,
        this.loggedInUser.fullName,
      );
      if (this.isEdit) {
        pushValue('requisitionNumber', this.editableData.requisitionNumber);
      }
      pushValue('assignmentId', this.localActiveUser.assignmentId);
      pushValue(
        'budgetUnit',
        this.localActiveUser.budgetUnitCode,
        this.localActiveUser.budgetUnitName,
      );

      // CommonData
      pushValue(
        'cfoConsumer',
        storeCommonData.commonDataCfd.code,
        storeCommonData.commonDataCfd.name,
      );
      pushValue(
        'expenseItem',
        storeCommonData.commonDataArticles.id,
        storeCommonData.commonDataArticles.name,
      );
      pushValue(
        'projectCode',
        storeCommonData.project ? storeCommonData.project.projectCode : null,
        storeCommonData.project ? storeCommonData.project.longName : null,
      );
      pushValue(
        'businessProcessCode',
        storeCommonData.businessProcess ? storeCommonData.businessProcess.id : null,
        storeCommonData.businessProcess ? storeCommonData.businessProcess.name : null,
      );
      pushValue(
        'codeProgram',
        storeCommonData.codeProgram ? storeCommonData.codeProgram.id : null,
        storeCommonData.codeProgram ? storeCommonData.codeProgram.name : null,
      );
      pushValue('mobilePhone', storeCommonData.phone);
      pushValue('aim', storeCommonData.commonDataTarget.name);

      const aimDescription = storeCommonData.commonDataTarget && storeCommonData.commonDataTarget.description
        ? storeCommonData.details
        : 'Без детализации';
      pushValue('aimDescription', aimDescription);

      const headId = storeCommonData.head.id || storeCommonData.head.tabNumber;
      const headName = storeCommonData.head.name || storeCommonData.head.fullName;
      pushValue('chiefEmployeeNumber', headId, headName);

      // expenses
      const destinationOrganization = this.checkFieldDependence(
        this.expensesOrganization,
        this.organizations[1],
      )
        ? this.expenses.otherOrganization
        : this.organizations[0].name;
      pushValue('destinationOrganization', destinationOrganization);

      const commonDayStart = dayjs(this.expenses.commonDayStart).format('DD.MM.YYYY');
      const commonDayEnd = dayjs(this.expenses.commonDayEnd).format('DD.MM.YYYY');
      pushValue('startDate', commonDayStart);
      pushValue('endDate', commonDayEnd);

      const avansAmount = this.expenses.isSelfBooking
        ? this.expenses.avansAmount
        : null;
      pushValue('avansAmount', avansAmount);

      const avansAmountComment = this.expenses.isSelfBooking
        ? this.expenses.avansAmountComment
        : null;
      pushValue(
        'avansAmountComment',
        avansAmountComment ? avansAmountComment.name : null,
      );

      const isNeedHelpFindingTicket = this.expenses.isNeedHelp ? 'да' : 'нет';
      pushValue('isNeedHelpFindingTicket', isNeedHelpFindingTicket);

      pushValue('tripSum', this.commonCost.toFixed(2));

      pushValue('freeFundsAmount', null);

      // 1 город отправляется в корне массива,
      // последующие в собираются в массив place
      const destination = this.expenses.cities.length
        ? await destinationPlacesItem(this.expenses.cities[0])
        : null;
      pushValue(
        'destinationPlaceTerritory',
        destination ? 'Российская Федерация' : null,
      );
      pushValue(
        'destinationPlaceRegion',
        destination ? destination.region : null,
        destination ? destination.region : null,
        valueArr,
        null,
        {
          placeInfoName: 'placeInfo',
          placeInfoValue: this.expenses.cities[0].region,
        },
      );
      pushValue(
        'destinationPlaceArea',
        destination ? destination.area : null,
        destination ? destination.area : null,
        valueArr,
        null,
        {
          placeInfoName: 'placeInfo',
          placeInfoValue: this.expenses.cities[0].area,
        },
      );
      pushValue(
        'destinationPlaceTown',
        destination ? destination.town : null,
        destination ? destination.town : null,
        valueArr,
        null,
        {
          placeInfoName: 'placeInfo',
          placeInfoValue: this.expenses.cities[0].city,
        },
      );
      pushValue(
        'destinationPlaceSettlement',
        destination ? destination.settlement : null,
        destination ? destination.settlement : null,
        valueArr,
        null,
        {
          placeInfoName: 'placeInfo',
          placeInfoValue: this.expenses.cities[0].settlement,
        },
      );

      const place = await addPlaces();
      valueArr.push({
        id: 'place',
        groupValue: place,
        groupTemplate: null,
        label: CHECKLIST_DATA.place.label,
      });

      const groupBooking = this.expenses.groupBooking ? 'да' : 'нет';
      pushValue('groupBooking', groupBooking);

      let hotelsGroupTemplate = null;
      if (this.accommodation && this.accommodation.length) {
        this.accommodation.forEach(pushHotels);
      } else {
        hotelsGroupTemplate = pushEmptyHotels();
      }
      valueArr.push({
        id: 'hotels',
        groupValue: hotels,
        groupTemplate: hotelsGroupTemplate,
        label: CHECKLIST_DATA.hotels.label,
      });

      let ticketsGroupTemplate = null;
      if (this.transit && this.transit.length) {
        this.transit.forEach(pushTickets);
      } else {
        ticketsGroupTemplate = pushEmptyTickets();
      }
      valueArr.push({
        id: 'tickets',
        groupValue: tickets,
        groupTemplate: ticketsGroupTemplate,
        label: CHECKLIST_DATA.tickets.label,
      });

      return valueArr;
    },
    // отправка данных
    async sendBusinessTrip() {
      if (this.isSending) return;

      const isValid = await this.validateFields();
      if (!isValid) return;

      this.isSending = true;
      const requestBody = new FormData();
      const createTicketEntity = {};
      const checklistItems = await this.getBusinessTrip();

      const destination = this.expenses.cities
        .map((e) => {
          const d = getDestinationCity(e);
          return `${d.abbrev}. ${d.name}`;
        })
        .join(', ');
      const dateStart = dayjs(this.expenses.commonDayStart).format('DD.MM.YYYY');
      const dateEnd = dayjs(this.expenses.commonDayEnd).format('DD.MM.YYYY');

      createTicketEntity.title = `Командировка в ${destination} с ${dateStart} по ${dateEnd}`;
      createTicketEntity.sourceSystem = 'otrs1';
      createTicketEntity.description = 'Создание заявки на командировку';
      createTicketEntity.typeId = '4';
      createTicketEntity.queue = 'Обработка заявок на командировку КЦ';
      createTicketEntity.priority = '1 normal';
      createTicketEntity.checklistItems = checklistItems;
      createTicketEntity.contactLogin = `${this.loggedInUser.login}`;
      createTicketEntity.customersLogins = [
        this.localActiveUser.email || this.localActiveUser.tabNumber,
      ];

      requestBody.append(
        'createTicketEntity',
        JSON.stringify(createTicketEntity),
      );

      this.setLoading({ key: 'page', value: true });
      this.$API.businessTrip.sendBusinessTrip(requestBody)
        .then(() => {
          this.$EsmpNotify.$show('Ваша заявка успешно создана', 'success');
          this.$router.push({
            name: 'Tickets',
            params: { category: 'active' },
          });
        })
        .catch((err) => {
          this.showModals.title = 'Ошибка создания заявки';
          this.showModals.msg = err && err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : err;
          this.toggleModal({ modalName: 'infoModal', newValue: true });
        })
        .finally(() => {
          this.isSending = false;
          this.setLoading({ key: 'page', value: false });
        });
    },

    // юзер
    getUser(user) {
      const params = {};
      if (user.login && EMAIL_REGEX.test(user.login)) {
        params.email = user.login;
      } else {
        params.assignmentNumber = user.employeeNumber;
      }
      return this.$API.businessTrip.user(params)
        .then((response) => response.data);
    },
    getUserByNumber(tabNumber, assignmentId) {
      return this.$API.businessTrip.userSearchByNumber({
        params: {
          tabNumber,
          assignmentId,
        },
      })
        .then((response) => response.data);
    },
    async getUserHead(name, tabNumber) {
      const users = await this.$API.businessTrip.userSearch(name)
        .then((response) => response.data);

      if (users && users.length) {
        return users.find((u) => u.tabNumber === tabNumber);
      }

      return null;
    },
    checkUserGPH(user) {
      if (user.isGPH) {
        this.showModals.title = 'Ошибка доступа';
        this.showModals.msg = 'Выбранному пользователю услуга заказа командировок не доступна';
        this.toggleModal({ modalName: 'infoModal', newValue: true });
      }
    },
    clearUserCity() {
      this.localUserCity = null;
      this.updateTransit({
        index: 0,
        field: 'startPoint',
        value: null,
      });
      this.updateTransit({
        index: this.transit.length - 1,
        field: 'endPoint',
        value: null,
      });
    },
    async getUserCity(user) {
      if (user?.location) {
        let city;
        await this.$API.businessTrip.citySearch({
          query: user.location,
        })
          .then((response) => {
            const citiesList = response.data?.filter((c) => (c.name.toLowerCase() === user.location.toLowerCase()));
            if (citiesList?.length === 1) {
              [city] = citiesList;
            }
          });

        if (city) {
          const value = {
            id: this.value ? this.value.id : null,
            region: null,
            area: null,
            city: null,
            settlement: null,
          };
          if (!isEmpty(city)) {
            if (city.isRegion) {
              value.region = city;
            } else if (city.code) {
              value.city = city;

              const regionCode = `${city.code.substr(0, 2)}000000000`;
              const areaCode = `${city.code.substr(0, 5)}000000`;

              value.region = await this.getRegion(regionCode);

              if (areaCode !== regionCode) {
                value.area = await this.getArea({ regionCode, areaCode });
              }
            }
          }

          this.localUserCity = value;
          if (this.transit.length) {
            this.updateTransit({
              index: 0,
              field: 'startPoint',
              value: { ...value },
            });
            this.updateTransit({
              index: this.transit.length - 1,
              field: 'endPoint',
              value: { ...value },
            });
          }
        } else {
          this.clearUserCity();
        }
      } else {
        this.clearUserCity();
      }
    },

    // инит при создании
    init() {
      this.getConstants();
      this.getTargets();
      this.getLimits();
      this.getCfd().then(() => {
        this.$refs.step1.findBusinessProcesses();
      });

      this.addTransit(true); //  to target city
      this.addExpensesCity();
      this.addAccommodation();
      this.addTransit(true); //  from target city

      if (this.selectedOrLoggedInUser) {
        const isSameUser = isEqual(this.selectedOrLoggedInUser, this.loggedInUser);
        this.getUser(this.selectedOrLoggedInUser).then((response) => {
          if (isSameUser) this.localInitiatorUser = cloneDeep(response);
          if (!this.localActiveUser) this.localActiveUser = cloneDeep(response);
        });

        if (!isSameUser) {
          this.getUser(this.loggedInUser).then((response) => {
            this.localInitiatorUser = cloneDeep(response);
          });
        }
      }
    },
    // инит при редактировании
    async getEditableData() {
      this.isLoadStep = true;
      this.setLoading({ key: 'page', value: true });

      // константы и функции
      const russianRegions = await this.$API.businessTrip.getAddresses({
        searchType: 0,
      })
        .then((response) => response.data);

      const getDestination = getDestinationFunc(this.$API, russianRegions);
      const setCity = setCityFunc(this.$API, this.getRegion, this.getArea);
      const setTransit = setTransitFunc(this, setCity);
      const setHotels = setHotelsFunc(this, setCity);

      // получение и простановка данных
      await this.getConstants();

      this.addExpensesCity();

      // set initiator User
      const isInitiatorActiveUser = this.editableData.initiatorEmployeeNumber === this.editableData.employeeNumber;
      if (!isInitiatorActiveUser && this.editableData.initiatorEmployeeNumber) {
        this.localInitiatorUser = {
          tabNumber: `${this.editableData.initiatorEmployeeNumber}`,
        };
      }

      // set active User (assignmentId, budgetUnit, initiator User)
      if (this.editableData.employeeNumber) {
        await this.getUserByNumber(
          this.editableData.employeeNumber,
          this.editableData.assignmentId,
        ).then(async (response) => {
          this.localActiveUser = cloneDeep(response);

          this.$nextTick(() => {
            // set mobilePhone
            if (this.editableData.mobilePhone) {
              this.setCommonDataField({
                field: 'phone',
                value: `${this.editableData.mobilePhone}`,
              });
            }
          });

          if (isInitiatorActiveUser) {
            this.localInitiatorUser = cloneDeep(response);
          }

          // set chiefEmployeeNumber (head)
          const isSomeHead = this.localActiveUser.managerId === this.editableData.chiefEmployeeNumber;
          if (!isSomeHead && this.editableData.chiefEmployeeNumber) {
            const headName = this.data.find(
              (field) => field.id === 'chiefEmployeeNumber',
            ).valueText;
            const head = await this.getUserHead(
              headName,
              this.editableData.chiefEmployeeNumber,
            );
            this.setCommonDataField({
              field: 'head',
              value: head,
            });
          }
        });
      }

      // set cfoConsumer / cfd
      if (this.editableData.cfoConsumer) {
        await this.getCfd().then(this.$refs.step1.findBusinessProcesses);
      }

      // set expenseItem / articles
      if (this.editableData.expenseItem) {
        const article = this.articles.find(
          (item) => item.id === this.editableData.expenseItem,
        );
        if (article) {
          this.setCommonDataField({
            field: 'articles',
            value: cloneDeep(article).id,
          });
        }
      }

      // set projectCode
      if (this.editableData.projectCode) {
        await this.$API.businessTrip.investProjectSearchByCode({
          params: this.editableData.projectCode,
        })
          .then((response) => {
            this.setCommonDataField({
              field: 'project',
              value: response.data,
            });
          });
      }

      // set businessProcessCode
      if (this.editableData.businessProcessCode) {
        if (!this.businessProcesses || !this.businessProcesses.length) {
          this.$refs.step1.findBusinessProcesses();
        }
      }

      // set codeProgram
      if (this.editableData.codeProgram) {
        this.setCommonDataField({
          field: 'hasCodeProgram',
          value: true,
        });
        this.$nextTick(() => {
          const codeProgramName = this.data.find(
            (field) => field.id === 'codeProgram',
          ).valueText;
          const codeProgram = {
            id: this.editableData.codeProgram,
            name: codeProgramName,
          };
          this.setCommonDataField({
            field: 'codeProgram',
            value: codeProgram.id,
          });
        });
      }

      // set avansAmount
      if (
        this.editableData.avansAmount
        && this.editableData.avansAmountComment
      ) {
        this.updateExpenses({
          field: 'isSelfBooking',
          value: true,
        });
        this.updateExpenses({
          field: 'avansAmount',
          value: `${this.editableData.avansAmount}`,
        });
        await this.getConstants();

        const comment = this.avansComments.find(
          (ac) => ac.name === this.editableData.avansAmountComment,
        );
        if (comment) {
          this.updateExpenses({
            field: 'avansAmountComment',
            value: cloneDeep(comment),
          });
        }
      }

      // set aim (target)
      if (this.editableData.aim) {
        await this.getTargets().then(() => {
          this.$nextTick(() => {
            const target = this.targets.find(
              (item) => item.name === this.editableData.aim,
            );
            if (target) {
              this.setCommonDataField({
                field: 'target',
                value: target.id,
              });
            }
          });
        });
      }

      // set aimDescription (details)
      if (this.editableData.aimDescription) {
        this.setCommonDataField({
          field: 'details',
          value: `${this.editableData.aimDescription}`,
        });
      }

      // set destinationOrganization
      if (this.editableData.destinationOrganization) {
        if (
          !RTK_NAMES.some((n) => n === this.editableData.destinationOrganization)
        ) {
          this.updateExpenses({
            field: 'organization',
            // eslint-disable-next-line prefer-object-spread
            value: this.organizations[1].id,
          });
          this.updateExpenses({
            field: 'otherOrganization',
            value: `${this.editableData.destinationOrganization}`,
          });
        }
      }

      // set startDate
      if (this.editableData.startDate) {
        this.updateExpenses({
          field: 'commonDayStart',
          value: getDate(this.editableData.startDate),
        });
      }

      // set endDate
      if (this.editableData.endDate) {
        this.updateExpenses({
          field: 'commonDayEnd',
          value: getDate(this.editableData.endDate),
        });
      }

      // set isNeedHelpFindingTicket
      if (this.editableData.isNeedHelpFindingTicket) {
        this.updateExpenses({
          field: 'isNeedHelp',
          value: this.editableData.isNeedHelpFindingTicket === 'да',
        });
      }

      // set groupBooking
      if (this.editableData.groupBooking) {
        this.updateExpenses({
          field: 'groupBooking',
          value: this.editableData.groupBooking === 'да',
        });
      }

      await this.getLimits();

      // set destination
      // ___ set destination first
      if (this.editableData.destinationPlaceRegion) {
        let destination = null;
        if (
          checkDestinationValue({
            region: getNewDestination('destinationPlaceRegion'),
            city: getNewDestination('destinationPlaceTown'),
            settlement: getNewDestination('destinationPlaceSettlement'),
          })
        ) {
          destination = {
            region: getNewDestination('destinationPlaceRegion').placeInfo,
            area: getNewDestination('destinationPlaceArea').placeInfo || null,
            city: getNewDestination('destinationPlaceTown').placeInfo || null,
            settlement:
              getNewDestination('destinationPlaceSettlement').placeInfo || null,
          };
        } else {
          destination = await getDestination(
            this.editableData.destinationPlaceRegion,
            this.editableData.destinationPlaceArea,
            this.editableData.destinationPlaceTown,
            this.editableData.destinationPlaceSettlement,
          );
        }

        destination.id = this.expenses.cities[0].id;

        this.updateExpensesCity({
          index: 0,
          city: destination,
        });
      }
      // ___ set destination another
      if (this.editableData.place && this.editableData.place.length) {
        for (
          let index = 0;
          index < this.editableData.place.length;
          index += 1
        ) {
          this.addExpensesCity();
          const place = this.editableData.place[index];

          const destinationPlaceRegion = place.find(
            (item) => item.id === 'destinationPlaceRegion',
          );
          const destinationPlaceArea = place.find(
            (item) => item.id === 'destinationPlaceArea',
          );
          const destinationPlaceTown = place.find(
            (item) => item.id === 'destinationPlaceTown',
          );
          const destinationPlaceSettlement = place.find(
            (item) => item.id === 'destinationPlaceSettlement',
          );

          let destination = null;

          if (
            checkDestinationValue({
              region: destinationPlaceRegion,
              city: destinationPlaceTown,
              settlement: destinationPlaceSettlement,
            })
          ) {
            destination = {
              region: destinationPlaceRegion.placeInfo,
              area: destinationPlaceArea.placeInfo || null,
              city: destinationPlaceTown.placeInfo || null,
              settlement: destinationPlaceSettlement.placeInfo || null,
            };
          } else {
            // eslint-disable-next-line no-await-in-loop
            destination = await getDestination(
              destinationPlaceRegion.value,
              destinationPlaceArea.value,
              destinationPlaceTown.value,
              destinationPlaceSettlement.value,
            );
          }

          destination.id = this.expenses.cities[index + 1].id;

          this.updateExpensesCity({
            index: index + 1,
            city: destination,
          });
        }
      }

      if (this.editableData.hotels && this.editableData.hotels.length) {
        await setHotels();
      }
      if (this.editableData.tickets && this.editableData.tickets.length) {
        await setTransit();
      }

      this.setLoading({ key: 'page', value: false });
      this.isLoadStep = false;
    },
    cancelEdit() {
      this.$emit('close-edit', false);
    },
    async saveEdit() {
      const { source, id } = this.$route.params;
      const checklistItems = await this.getBusinessTrip();

      const requestBody = {
        checklistItems,
        sourceSystem: source,
        ticketId: id,
      };

      this.setLoading({ key: 'page', value: true });
      this.$API.businessTrip.updateTicket(id, requestBody)
        .then(() => {
          this.$EsmpNotify.$show('Ваша заявка успешно изменена', 'success');
          this.$router.push({
            name: 'Tickets',
            params: { category: 'active' },
          });
        })
        .catch((err) => {
          this.showModals.title = 'Ошибка изменения заявки';
          this.showModals.msg = err && err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : err;
          this.toggleModal({ modalName: 'infoModal', newValue: true });
        })
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
  },
  watch: {
    selectedOrLoggedInUser(val) {
      if (val) {
        // подстановка инцциализатора и текущего юзера
        this.getUser(val).then((response) => {
          this.localInitiatorUser = { ...response };
          this.localActiveUser = { ...response };
        });
      }
    },
    activeUser(val) {
      this.getUserCity(val);
      if (val) this.checkUserGPH(val);
    },
  },
  created() {
    this.clearExpenses();
    this.clearTransit();

    // для потомков
    this.setIsEdit(this.isEdit);

    if (this.isEdit) {
      this.getEditableData();
    } else {
      this.init();
    }
  },
  destroyed() {
    this.clearState();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .esmp-datepicker-dropdown {
  z-index: 100;
}
.page__action-buttons {
  z-index: 10;
}

.business-trip {
  &__result {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    background-color: $color-white;
    padding: 20px 16px;
    border-radius: $base-border-radius;
    margin-top: 20px;
  }
  &__result-inner {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
  }
  &__result-name {
    font-size: 16px;
    letter-spacing: 0;
    &:not(:first-child) {
      margin-left: 20px;
    }
  }
  &__result-value {
    font-size: 24px;
    color: $color-black;
    letter-spacing: 0;
    margin-left: 16px;
  }
  &__result-actions {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-left: -20px;
    ::v-deep .esmp-button {
      margin-left: 20px;
    }
  }

  &__footnote ::v-deep .esmp-link {
    font-size: 16px;
  }
  &__error,
  ::v-deep &__error {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 16px;
    word-break: break-word;
    font-feature-settings: "tnum", "lnum";
    padding: 0 12px;
    color: $color-error-night;
   }
}
</style>
