import isEqual from 'lodash/isEqual';

const checkFieldDependence = (target, value, isReverse) => {
  let result = false;
  if (target && value) result = isEqual(target, value);

  return isReverse ? !result : result;
};

export default checkFieldDependence;
