var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"common-data"},[_c('validation-observer',{ref:"common"},[_c('div',{staticClass:"common-data__row"},[_c('div',{staticClass:"common-data__cell"},[_c('validation-provider',{attrs:{"name":"Cотрудник","rules":"required|dontGPH"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('user-search',{attrs:{"placeholder":"Сотрудник","error-message":errors[0]},model:{value:(_vm.localActiveUser),callback:function ($$v) {_vm.localActiveUser=$$v},expression:"localActiveUser"}})]}}])})],1)]),_c('div',{staticClass:"common-data__row"},[_c('div',{staticClass:"common-data__cell"},[_c('validation-provider',{attrs:{"name":"Мобильный телефон","rules":{ required: true, regex: _vm.PHONE_REGEX }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('esmp-input',{attrs:{"label":"Мобильный телефон в формате +7ХХХХХХХХХХ","error-message":errors[0],"value":_vm.commonData.phone},on:{"input":function($event){return _vm.setCommonDataField({
                field: 'phone',
                value: $event,
              })}}})]}}])})],1),_c('div',{staticClass:"common-data__cell"},[_c('validation-provider',{attrs:{"name":"Цель командировки","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('esmp-select',{attrs:{"placeholder":"Цель командировки","filterable":"","clearable":"","error-message":errors[0],"value":_vm.commonData.target},on:{"on-select":_vm.setArticle,"input":function($event){return _vm.setCommonDataField({
                field: 'target',
                value: $event,
              })}}},_vm._l((_vm.targets),function(item){return _c('esmp-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)]}}])})],1)]),(_vm.localTarget && _vm.localTarget.description)?_c('div',{staticClass:"common-data__row"},[_c('div',{staticClass:"common-data__cell"},[_c('validation-provider',{attrs:{"name":"Детали командировки","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('esmp-input',{attrs:{"label":_vm.targetDetailsPlaceholder,"error-message":errors[0],"value":_vm.commonData.details},on:{"input":function($event){return _vm.setCommonDataField({
                field: 'details',
                value: $event,
              })}}})]}}],null,false,3155830616)})],1)]):_vm._e(),_c('div',{staticClass:"common-data__row"},[_c('div',{staticClass:"common-data__cell"},[_c('validation-provider',{attrs:{"name":"ЦФО-потребителя","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('esmp-select',{attrs:{"placeholder":"ЦФО-потребителя","disabled":"","error-message":errors[0],"value":_vm.commonData.cfd},on:{"on-select":_vm.findBusinessProcesses,"input":function($event){return _vm.setCommonDataField({
                field: 'cfd',
                value: $event,
              })}}},_vm._l((_vm.cfdList),function(item){return _c('esmp-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)]}}])})],1),_c('div',{staticClass:"common-data__cell"},[_c('validation-provider',{attrs:{"name":"Статья","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('esmp-select',{attrs:{"placeholder":"Статья","filterable":"","clearable":"","error-message":errors[0],"value":_vm.commonData.articles},on:{"on-select":_vm.findBusinessProcesses,"input":function($event){return _vm.setCommonDataField({
                field: 'articles',
                value: $event,
              })}}},_vm._l((_vm.articles),function(item){return _c('esmp-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)]}}])})],1)]),(_vm.checkFieldDependence(_vm.localArticles, _vm.articles[3]))?_c('div',{staticClass:"common-data__row"},[_c('div',{staticClass:"common-data__cell"},[_c('validation-provider',{attrs:{"name":"Проект","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('investment-project',{attrs:{"value":_vm.commonData.project,"error-message":errors[0]},on:{"input":function($event){return _vm.setCommonDataField({
                field: 'project',
                value: $event,
              })}}}),(errors[0])?_c('div',{staticClass:"business-trip__error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,2911334209)})],1)]):_vm._e(),_c('div',{staticClass:"common-data__row"},[_c('div',{staticClass:"common-data__cell business-trip__search"},[_c('validation-provider',{attrs:{"name":"Руководитель подразделения","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('user-search',{attrs:{"placeholder":"Руководитель подразделения","is-valid":true,"error-message":errors[0],"value":_vm.commonData.head},on:{"input":function($event){return _vm.setCommonDataField({
                field: 'head',
                value: $event,
              })}}})]}}])})],1),_c('div',{staticClass:"common-data__cell"},[(_vm.checkFieldDependence(_vm.localArticles, _vm.articles[3], true))?[_c('validation-provider',{attrs:{"name":"Бизнес-процесс","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('esmp-select',{attrs:{"placeholder":"Бизнес-процесс","filterable":"","clearable":"","error-message":errors[0],"value":_vm.commonData.businessProcess},on:{"on-select":_vm.getCodeProgramsList,"input":function($event){return _vm.setCommonDataField({
                  field: 'businessProcess',
                  value: $event,
                })}}},_vm._l((_vm.businessProcessesList),function(item){return _c('esmp-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)]}}],null,false,2311938507)})]:_vm._e()],2)]),(_vm.checkFieldDependence(_vm.localArticles, _vm.articles[3], true))?_c('div',{staticClass:"common-data__row"},[_c('div',{staticClass:"common-data__cell"},[_c('esmp-checkbox',{attrs:{"value":_vm.commonData.hasCodeProgram},on:{"input":function($event){return _vm.setCommonDataField({
              field: 'hasCodeProgram',
              value: $event,
            })}}},[_vm._v(" Указать код программы ")])],1),_c('div',{staticClass:"common-data__cell"},[(_vm.commonData.hasCodeProgram)?[_c('validation-provider',{attrs:{"name":"Код программы","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('esmp-select',{attrs:{"placeholder":"Код программы","filterable":"","clearable":"","error-message":errors[0],"value":_vm.commonData.codeProgram},on:{"on-select":_vm.getCodeProgramsList,"input":function($event){return _vm.setCommonDataField({
                  field: 'codeProgram',
                  value: $event,
                })}}},_vm._l(((_vm.codePrograms || [])),function(item){return _c('esmp-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)]}}],null,false,2847679694)})]:_vm._e()],2)]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }