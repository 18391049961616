const checkDestinationValue = ({ region, city, settlement }) => {
  // eslint-disable-next-line no-nested-ternary
  const direction = settlement.value
    ? settlement
    : city.value
      ? city
      : region;

  if (direction && direction.placeInfo) {
    return (direction.value === `${direction.placeInfo.name} ${direction.placeInfo.abbrev}`);
  }

  return false;
};

export default checkDestinationValue;
