var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accommodation-block"},[_c('div',{staticClass:"business-trip__expenses-row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"business-trip__expenses-col"},[_c('div',{staticClass:"business-trip__expenses-fieldset"},[_c('div',{staticClass:"business-trip__expenses-field business-trip__expenses-field--big"},[_c('esmp-select',{attrs:{"placeholder":"Территориальная зона","filterable":"","clearable":"","value":_vm.fields.zone ? _vm.fields.zone.id : null},on:{"input":function($event){return _vm.update({
                field: 'zone',
                value: $event,
                list: _vm.territorialZones,
              })}}},_vm._l((_vm.territorialZones),function(item){return _c('esmp-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)])]),_c('div',{staticClass:"business-trip__expenses-col"},[_c('div',{staticClass:"business-trip__expenses-fieldset"},[_c('div',{staticClass:"business-trip__expenses-field business-trip__expenses-field--big"},[_c('validation-provider',{attrs:{"name":`Место назначения ${(_vm.currentIndex + 1)}`,"rules":"required|city"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('destination-field',{attrs:{"is-valid":true,"value":_vm.fields.destination},on:{"change":_vm.setZone,"input":function($event){return _vm.update({
                  field: 'destination',
                  value: $event,
                })}}}),(errors[0])?_c('div',{staticClass:"business-trip__error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)])]),_c('div',{staticClass:"business-trip__expenses-col"},[_c('div',{staticClass:"business-trip__expenses-fieldset"},[_c('div',{staticClass:"business-trip__expenses-field business-trip__expenses-field--big"},[_c('validation-provider',{attrs:{"name":`Отель ${(_vm.currentIndex + 1)}`,"rules":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('hotel-search',{attrs:{"city":_vm.fields.destination ? _vm.fields.destination : null,"is-valid":true,"value":_vm.fields.hotel},on:{"input":function($event){return _vm.update({
                  field: 'hotel',
                  value: $event,
                })}}}),(errors[0])?_c('div',{staticClass:"business-trip__error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)])]),_c('div',{staticClass:"business-trip__expenses-col"},[_c('div',{staticClass:"business-trip__expenses-fieldset"},[_c('div',{staticClass:"business-trip__expenses-field"},[_c('validation-provider',{attrs:{"name":`Заезд ${(_vm.currentIndex + 1)}`,"rules":{ required: true, datePeriod: { endDay: _vm.fields.dayEnd } }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('esmp-datepicker-adaptive',{attrs:{"placeholder":"Заезд","value":_vm.fields.dayStart},on:{"input":function($event){return _vm.update({
                  field: 'dayStart',
                  value: $event,
                })}}}),(errors[0])?_c('div',{staticClass:"business-trip__error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"business-trip__expenses-field"},[_c('validation-provider',{attrs:{"name":`Выезд ${(_vm.currentIndex + 1)}`,"rules":{ required: true, datePeriod: { startDay: _vm.fields.dayStart } }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('esmp-datepicker-adaptive',{attrs:{"placeholder":"Выезд","value":_vm.fields.dayEnd},on:{"input":function($event){return _vm.update({
                  field: 'dayEnd',
                  value: $event,
                })}}}),(errors[0])?_c('div',{staticClass:"business-trip__error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)])]),_c('div',{staticClass:"business-trip__expenses-col"},[_c('esmp-checkbox',{attrs:{"value":_vm.fields.requireMatch},on:{"input":function($event){return _vm.update({
            field: 'requireMatch',
            value: $event,
          })}}},[_vm._v(" Требуется подбор ")])],1),(_vm.isAvailableRemove)?_c('div',{staticClass:"business-trip__expenses-col"},[_c('action-btn',{attrs:{"is-remove":true},on:{"click":_vm.removeAccommodation}},[_vm._v(" Удалить отель ")])],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }