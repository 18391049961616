<template>
  <div>
    <esmp-select
      :placeholder="placeholder"
      filterable
      :remote-method="waitProjectPrint"
      :loading="loading"
      :show-search-results="showSearchResults"
      :error-message="errorMessage"
      v-model="val"
      @on-query-change="(v) => query = v"
      @on-select="selected"
    >
      <esmp-select-option
        v-for="r in dropDownResults"
        :key="r.projectId"
        :value="r.projectId"
        :label="r.longName"
      >
        <span v-html="r.longName" />
      </esmp-select-option>
    </esmp-select>
  </div>
</template>

<script>
import { SEARCH_TIMEOUT_DELAY } from '@/constants/business-trip2';

export default {
  name: 'InvestmentProject',
  props: {
    placeholder: {
      type: String,
      default: 'Инвестиционный проект',
    },
    value: {
      type: Object,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      val: '',
      dropDownResults: [],
      loading: false,
      query: '',
      searchTimeoutId: null,
      showSearchResults: true,
    };
  },
  methods: {
    async search() {
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);

      if (this.query.trim().length >= 3) {
        this.loading = true;

        await this.$API.businessTrip.investProjectSearch({
          query: this.query,
        })
          .then((response) => {
            this.dropDownResults = response.data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    waitProjectPrint(query) {
      if (this.value && query === this.value.longName) return;
      if (this.value && query === this.value.projectId) return;
      if (this.value) {
        this.$emit('input', null);
      }

      this.dropDownResults = null;

      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);
      this.searchTimeoutId = setTimeout(this.search, SEARCH_TIMEOUT_DELAY);
    },
    selected(project) {
      const projectValue = project ? this.dropDownResults.find((r) => r.projectId === project.value) : null;
      this.$emit('input', projectValue);
      this.val = projectValue.projectId;
    },
  },
  watch: {
    value(val) {
      if (val && this.query !== val.longName) {
        this.dropDownResults = [val];
        this.val = val.projectId;
      }
    },
  },
  created() {
    if (this.value) {
      this.dropDownResults = [this.value];
      this.val = this.value.projectId;
    }
  },
};
</script>
