import dayjs from 'dayjs';

import { createFullPlaceInfo } from './createFullPlaceInfo';
import getDestinationCity from './getDestinationCity';

const pushHotelsFunc = (hotels, pushValue, getOldValue) => (hotel, index) => {
  const arr = [];

  pushValue(
    'territoryZone',
    hotel.zone.id,
    hotel.zone.name,
    arr,
    getOldValue('hotels', index, 'territoryZone'),
  );

  pushValue(
    'city',
    getDestinationCity(hotel.destination).name,
    getDestinationCity(hotel.destination).name,
    arr,
    getOldValue('hotels', index, 'city'),
    {
      placeInfoName: 'fullPlaceInfo',
      placeInfoValue: createFullPlaceInfo(hotel.destination),
    },
  );

  const ticketStartDate = dayjs(hotel.dayStart).format(
    'YYYY-MM-DD',
  );
  pushValue(
    'residenceFrom',
    ticketStartDate,
    ticketStartDate,
    arr,
    getOldValue('hotels', index, 'residenceFrom'),
  );

  const residenceTo = dayjs(hotel.dayEnd).format(
    'YYYY-MM-DD',
  );
  pushValue(
    'residenceTo',
    residenceTo,
    residenceTo,
    arr,
    getOldValue('hotels', index, 'residenceTo'),
  );

  pushValue(
    'name',
    hotel.hotel,
    hotel.hotel,
    arr,
    getOldValue('hotels', index, 'name'),
  );

  pushValue(
    'category',
    'Согласно грейду',
    'Согласно грейду',
    arr,
    getOldValue('hotels', index, 'category'),
  );
  pushValue(
    'earlyArrive',
    '0',
    '0',
    arr,
    getOldValue('hotels', index, 'earlyArrive'),
  );
  pushValue(
    'lateDeparture',
    '0',
    '0',
    arr,
    getOldValue('hotels', index, 'lateDeparture'),
  );
  pushValue(
    'taxService',
    '1',
    '1',
    arr,
    getOldValue('hotels', index, 'taxService'),
  );

  const requireMatch = hotel.requireMatch ? '1' : '0';
  pushValue(
    'requireMatch',
    requireMatch,
    requireMatch,
    arr,
    getOldValue('hotels', index, 'requireMatch'),
  );

  hotels.push(arr);
};

export default pushHotelsFunc;
