import { BUSINESS_TRIP_CLASS } from '@/constants/business-trip2';

const pushEmptyTicketsFunc = (pushValue, getOldValue) => () => {
  const arr = [];

  pushValue(
    'ticketForm',
    null,
    null,
    arr,
    getOldValue('tickets', 0, 'ticketForm'),
  );
  pushValue(
    'territoryZone',
    null,
    null,
    arr,
    getOldValue('tickets', 0, 'territoryZone'),
  );
  pushValue(
    'tripClass',
    BUSINESS_TRIP_CLASS.economy,
    BUSINESS_TRIP_CLASS.economy,
    arr,
    getOldValue('tickets', 0, 'tripClass'),
  );
  pushValue(
    'ticketStartDate',
    null,
    null,
    arr,
    getOldValue('tickets', 0, 'ticketStartDate'),
  );
  pushValue(
    'tripNumber',
    null,
    null,
    arr,
    getOldValue('tickets', 0, 'tripNumber'),
  );
  pushValue(
    'departureFrom',
    null,
    null,
    arr,
    getOldValue('tickets', 0, 'departureFrom'),
  );
  pushValue(
    'departureTo',
    null,
    null,
    arr,
    getOldValue('tickets', 0, 'departureTo'),
  );
  pushValue(
    'taxService',
    '1',
    '1',
    arr,
    getOldValue('tickets', 0, 'taxService'),
  );
  pushValue(
    'timeOfDay',
    null,
    null,
    arr,
    getOldValue('tickets', 0, 'timeOfDay'),
  );

  return arr;
};

export default pushEmptyTicketsFunc;
