const pushEmptyHotelsFunc = (pushValue, getOldValue) => () => {
  const arr = [];

  pushValue(
    'territoryZone',
    null,
    null,
    arr,
    getOldValue('hotels', 0, 'territoryZone'),
  );
  pushValue('city', null, null, arr, getOldValue('hotels', 0, 'city'));
  pushValue(
    'residenceFrom',
    null,
    null,
    arr,
    getOldValue('hotels', 0, 'residenceFrom'),
  );
  pushValue(
    'residenceTo',
    null,
    null,
    arr,
    getOldValue('hotels', 0, 'residenceTo'),
  );
  pushValue('name', null, null, arr, getOldValue('hotels', 0, 'name'));
  pushValue(
    'category',
    'Согласно грейду',
    'Согласно грейду',
    arr,
    getOldValue('hotels', 0, 'category'),
  );
  pushValue(
    'earlyArrive',
    '1',
    '1',
    arr,
    getOldValue('hotels', 0, 'earlyArrive'),
  );
  pushValue(
    'lateDeparture',
    '1',
    '1',
    arr,
    getOldValue('hotels', 0, 'lateDeparture'),
  );
  pushValue(
    'taxService',
    '1',
    '1',
    arr,
    getOldValue('hotels', 0, 'taxService'),
  );
  pushValue(
    'requireMatch',
    '0',
    '0',
    arr,
    getOldValue('hotels', 0, 'requireMatch'),
  );

  return arr;
};

export default pushEmptyHotelsFunc;
