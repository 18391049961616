import destinationPlacesItem from './destinationPlacesItem';

const addPlacesFunc = (expenses, pushValue, getOldValue) => async () => {
  const place = [];

  for (let i = 0; i < expenses.cities.length; i += 1) {
    if (i) {
      const item = expenses.cities[i];
      const index = i + 1;

      const itemPlaceArr = [];

      // eslint-disable-next-line no-await-in-loop
      const itemDestination = await destinationPlacesItem(item);

      pushValue(
        'destinationPlaceTerritory',
        'Российская Федерация',
        'Российская Федерация',
        itemPlaceArr,
        getOldValue('place', index, 'destinationPlaceTerritory'),
      );
      pushValue(
        'destinationPlaceRegion',
        itemDestination.region,
        itemDestination.region,
        itemPlaceArr,
        getOldValue('place', index, 'destinationPlaceRegion'),
        {
          placeInfoName: 'placeInfo',
          placeInfoValue: item.region,
        },
      );
      pushValue(
        'destinationPlaceArea',
        itemDestination.area,
        itemDestination.area,
        itemPlaceArr,
        getOldValue('place', index, 'destinationPlaceArea'),
        {
          placeInfoName: 'placeInfo',
          placeInfoValue: item.area,
        },
      );
      pushValue(
        'destinationPlaceTown',
        itemDestination.town,
        itemDestination.town,
        itemPlaceArr,
        getOldValue('place', index, 'destinationPlaceTown'),
        {
          placeInfoName: 'placeInfo',
          placeInfoValue: item.city,
        },
      );
      pushValue(
        'destinationPlaceSettlement',
        itemDestination.settlement,
        itemDestination.settlement,
        itemPlaceArr,
        getOldValue('place', index, 'destinationPlaceSettlement'),
        {
          placeInfoName: 'placeInfo',
          placeInfoValue: item.settlement,
        },
      );

      place.push(itemPlaceArr);
    }
  }

  return place;
};

export default addPlacesFunc;
