const convertDataArrayToObj = (arr) => {
  if (arr && arr.length) {
    const value = {};
    arr.forEach((item) => {
      value[item.id] = item.value || item.rows;
    });

    return value;
  }

  return null;
};

export default convertDataArrayToObj;
