import isEmpty from 'lodash/isEmpty';

import { getRegionCode, getAreaCode } from './getDestinationCode';

const setCityFunc = ($API, getRegion, getArea) => async (region, town) => {
  const query = town || region;

  const queryText = query.replace(/(\sг)$/, '');

  const citySearchValue = this.$API.businessTrip.citySearch({
    query: queryText,
  })
    .then((response) => response.data.find((item) => item.name.toLowerCase() === queryText.toLowerCase()));

  const value = {
    id: null,
    region: null,
    area: null,
    city: null,
    settlement: null,
  };
  if (!isEmpty(citySearchValue)) {
    if (citySearchValue.isRegion) {
      value.region = citySearchValue;
    } else if (citySearchValue.code) {
      value.city = citySearchValue;

      const regionCode = getRegionCode(citySearchValue);
      const areaCode = getAreaCode(citySearchValue);

      value.region = await getRegion(regionCode);

      if (areaCode !== regionCode) {
        value.area = await getArea({ regionCode, areaCode });
      }
    }
  }

  return value;
};

export default setCityFunc;
