import convertDataArrayToObj from './convertDataArrayToObj';
import getNewDestination from './getNewDestination';
import getDestinationCity from './getDestinationCity';
import getDate from './getDate';
import { parseFullPlaceInfo } from './createFullPlaceInfo';

const setHotelsFunc = (ctx, setCity) => async () => {
  for (
    let index = 0;
    index < ctx.editableData.hotels.length;
    index += 1
  ) {
    const hotelArr = ctx.editableData.hotels[index];

    ctx.addAccommodation();

    const hotel = convertDataArrayToObj(hotelArr);

    const accommodation = ctx.accommodation[index];

    const zone = ctx.territorialZones.find(
      (z) => z.id === hotel.territoryZone,
    );
    ctx.updateAccommodation({
      index,
      field: 'zone',
      value: zone,
    });

    if (
      !accommodation.destination
      || hotel.city !== accommodation.destination.name
    ) {
      const city = getNewDestination('city', hotelArr);
      const cityFullPlaceInfo = city.fullPlaceInfo
        ? parseFullPlaceInfo(city.fullPlaceInfo)
        : null;
      if (
        cityFullPlaceInfo
        && getDestinationCity(cityFullPlaceInfo)
        && getDestinationCity(cityFullPlaceInfo).name === city.value
      ) {
        ctx.updateAccommodation({
          index,
          field: 'destination',
          value: cityFullPlaceInfo,
        });
      } else {
        // eslint-disable-next-line no-await-in-loop
        const destination = await setCity(null, hotel.city);
        ctx.updateAccommodation({
          index,
          field: 'destination',
          value: destination,
        });
      }
    }

    ctx.updateAccommodation({
      index,
      field: 'dayStart',
      value: getDate(hotel.residenceFrom),
    });

    ctx.updateAccommodation({
      index,
      field: 'dayEnd',
      value: getDate(hotel.residenceTo),
    });

    ctx.updateAccommodation({
      index,
      field: 'hotel',
      value: getDate(hotel.name),
    });

    ctx.updateAccommodation({
      index,
      field: 'requireMatch',
      value: hotel.requireMatch === '1',
    });
  }
};

export default setHotelsFunc;
