<template>
  <div class="accommodation-block">
    <div class="business-trip__expenses-row">
      <div class="business-trip__expenses-col" v-show="false">
        <div class="business-trip__expenses-fieldset">
          <div
            class="business-trip__expenses-field business-trip__expenses-field--big"
          >
            <esmp-select
              placeholder="Территориальная зона"
              filterable
              clearable
              :value="fields.zone ? fields.zone.id : null"
              @input="
                update({
                  field: 'zone',
                  value: $event,
                  list: territorialZones,
                })
              "
            >
              <esmp-select-option
                v-for="item in territorialZones"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </esmp-select-option>
            </esmp-select>
          </div>
        </div>
      </div>
      <div class="business-trip__expenses-col">
        <div class="business-trip__expenses-fieldset">
          <div
            class="business-trip__expenses-field business-trip__expenses-field--big"
          >
            <validation-provider
              :name="`Место назначения ${(currentIndex + 1)}`"
              rules="required|city"
              v-slot="{ errors }"
            >
              <destination-field
                :is-valid="true"
                :value="fields.destination"
                @change="setZone"
                @input="
                  update({
                    field: 'destination',
                    value: $event,
                  })
                "
              />
              <div v-if="errors[0]" class="business-trip__error">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>
        </div>
      </div>
      <div class="business-trip__expenses-col">
        <div class="business-trip__expenses-fieldset">
          <div
            class="business-trip__expenses-field business-trip__expenses-field--big"
          >
            <validation-provider
              :name="`Отель ${(currentIndex + 1)}`"
              rules=""
              v-slot="{ errors }"
            >
              <hotel-search
                :city="
                  fields.destination ? fields.destination : null
                "
                :is-valid="true"
                :value="fields.hotel"
                @input="
                  update({
                    field: 'hotel',
                    value: $event,
                  })
                "
              />
              <div v-if="errors[0]" class="business-trip__error">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>
        </div>
      </div>

      <div class="business-trip__expenses-col">
        <div class="business-trip__expenses-fieldset">
          <div class="business-trip__expenses-field">
            <validation-provider
              :name="`Заезд ${(currentIndex + 1)}`"
              :rules="{ required: true, datePeriod: { endDay: fields.dayEnd } }"
              v-slot="{ errors }"
            >
              <esmp-datepicker-adaptive
                placeholder="Заезд"
                :value="fields.dayStart"
                @input="
                  update({
                    field: 'dayStart',
                    value: $event,
                  })
                "
              />
              <div v-if="errors[0]" class="business-trip__error">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>
          <div class="business-trip__expenses-field">
            <validation-provider
              :name="`Выезд ${(currentIndex + 1)}`"
              :rules="{ required: true, datePeriod: { startDay: fields.dayStart } }"
              v-slot="{ errors }"
            >
              <esmp-datepicker-adaptive
                placeholder="Выезд"
                :value="fields.dayEnd"
                @input="
                  update({
                    field: 'dayEnd',
                    value: $event,
                  })
                "
              />
              <div v-if="errors[0]" class="business-trip__error">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>
        </div>
      </div>

      <div class="business-trip__expenses-col">
        <esmp-checkbox
          :value="fields.requireMatch"
          @input="
            update({
              field: 'requireMatch',
              value: $event,
            })
          "
        >
          Требуется подбор
        </esmp-checkbox>
      </div>

      <div class="business-trip__expenses-col" v-if="isAvailableRemove">
        <action-btn
          :is-remove="true"
          @click="removeAccommodation"
        >
          Удалить отель
        </action-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import DestinationField from '../components/destination/DestinationField.vue';
import ActionBtn from '../components/ActionBtn.vue';
import HotelSearch from '../components/HotelSearch.vue';
import getDestinationCity from '../helpers/getDestinationCity';

export default {
  name: 'AccommodationBlock',
  props: {
    fields: {
      type: Object,
      default: undefined,
    },
    isEdit: Boolean,
    currentIndex: {
      type: [Number, String],
      default: undefined,
    },
    isAvailableRemove: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ActionBtn,
    DestinationField,
    HotelSearch,
  },
  computed: {
    ...mapGetters('businessTrip2', ['territorialZones']),
    numberIndex() {
      return Number(this.currentIndex);
    },
  },
  methods: {
    ...mapActions('businessTrip2', ['updateAccommodation', 'removeAccommodationBlock']),
    update({ field, value, list }) {
      let val = value;
      if (list && list.length) {
        val = list.find((i) => i.id === value);
      }

      this.updateAccommodation({
        index: this.currentIndex,
        field,
        value: val,
      });
    },
    removeAccommodation() {
      this.removeAccommodationBlock(this.numberIndex);
    },
    setZone() {
      if (!this.fields.destination) return;
      let zone = null;
      const r = getDestinationCity(this.fields.destination);

      if (r) {
        zone = this.territorialZones.find((item) => item.id === r.zoneCode) || null;
      }

      this.update({
        field: 'zone',
        value: zone,
      });
    },
  },
};
</script>
