const getOldValueFunc = (isEdit, editableData) => (group, index, field) => {
  if (!isEdit) return null;

  const currentGroup = editableData[group];
  if (currentGroup && index < currentGroup.length) {
    const currentElement = currentGroup[index];
    const value = currentElement.find((item) => item.id === field);
    if (value) {
      return value.value;
    }
  }

  return null;
};

export default getOldValueFunc;
