import isEmpty from 'lodash/isEmpty';

const destinationPlacesItem = async (destination) => {
  let town = null;
  let area = null;
  let region = null;
  let settlement = null;

  if (destination.city && destination.city.isRegion) {
    region = `${destination.city.name} ${destination.city.abbrev}`;
  } else {
    if (!isEmpty(destination.city)) {
      town = `${destination.city.name} ${destination.city.abbrev}`;
    }

    if (!isEmpty(destination.settlement)) {
      settlement = `${destination.settlement.name} ${destination.settlement.abbrev}`;
    }

    const regionObj = await destination.region;
    region = `${regionObj.name} ${regionObj.abbrev}`;

    if (!isEmpty(destination.area)) {
      const areaObj = await destination.area;
      if (areaObj) area = `${areaObj.name} ${areaObj.abbrev}`;
    }
  }

  return {
    town,
    area,
    region,
    settlement,
  };
};

export default destinationPlacesItem;
