import {
  BUSINESS_TRIP_TICKET_FORM,
  BUSINESS_TRIP_CLASS,
  BUSINESS_TRIP_TRANSIT_WAYS,
} from '@/constants/business-trip2';

import convertDataArrayToObj from './convertDataArrayToObj';
import getNewDestination from './getNewDestination';
import getDestinationCity from './getDestinationCity';
import getDate from './getDate';
import { parseFullPlaceInfo } from './createFullPlaceInfo';

const setTransitFunc = (ctx, setCity) => async () => {
  for (
    let index = 0;
    index < ctx.editableData.tickets.length;
    index += 1
  ) {
    const ticketArr = ctx.editableData.tickets[index];
    ctx.addTransit(true);

    const ticket = convertDataArrayToObj(ticketArr);
    const transit = ctx.transit[index];

    const transitWay = Object.keys(BUSINESS_TRIP_TICKET_FORM).find(
      (itemKey) => BUSINESS_TRIP_TICKET_FORM[itemKey] === ticket.ticketForm,
    );
    ctx.updateTransit({
      index,
      field: 'transitWay',
      value: transitWay,
    });

    const zone = ctx.territorialZones.find(
      // eslint-disable-next-line no-shadow
      (zone) => zone.id === ticket.territoryZone,
    );
    ctx.updateTransit({
      index,
      field: 'zone',
      value: zone,
    });

    if (ticket.tripClass === BUSINESS_TRIP_CLASS.business) {
      if (transit.transitWay === BUSINESS_TRIP_TRANSIT_WAYS.plane) {
        ctx.updateTransit({
          index,
          field: 'isBusinessClass',
          value: true,
        });
      } else if (transit.transitWay === BUSINESS_TRIP_TRANSIT_WAYS.train) {
        ctx.updateTransit({
          index,
          field: 'isFirstClass',
          value: true,
        });
      }
    }

    ctx.updateTransit({
      index,
      field: 'day',
      value: getDate(ticket.ticketStartDate),
    });

    if (ticket.tripNumber) {
      ctx.updateTransit({
        index,
        field: `${transit.transitWay}TripNumber`,
        value: ticket.tripNumber,
      });
    }

    if (ticket.departureFrom) {
      let startPoint = null;
      const departureFrom = getNewDestination('departureFrom', ticketArr);
      const departureFromFullPlaceInfo = departureFrom.fullPlaceInfo
        ? parseFullPlaceInfo(departureFrom.fullPlaceInfo)
        : null;

      if (
        departureFromFullPlaceInfo
        && getDestinationCity(departureFromFullPlaceInfo)
        && getDestinationCity(departureFromFullPlaceInfo).name === departureFrom.value
      ) {
        startPoint = departureFromFullPlaceInfo;
      } else {
        // eslint-disable-next-line no-await-in-loop
        startPoint = await setCity(null, ticket.departureFrom);
      }

      this.updateTransit({
        index,
        field: 'startPoint',
        value: startPoint,
      });
    }

    if (
      !transit.destination
      || ticket.departureTo !== transit.endPoint.name
    ) {
      let endPoint = null;
      const departureTo = getNewDestination('departureTo', ticketArr);
      const departureToFullPlaceInfo = departureTo.fullPlaceInfo
        ? parseFullPlaceInfo(departureTo.fullPlaceInfo)
        : null;
      if (
        departureToFullPlaceInfo
        && getDestinationCity(departureToFullPlaceInfo)
        && getDestinationCity(departureToFullPlaceInfo).name === departureTo.value
      ) {
        endPoint = departureToFullPlaceInfo;
      } else {
        // eslint-disable-next-line no-await-in-loop
        endPoint = await setCity(null, ticket.departureTo);
      }

      this.updateTransit({
        index,
        field: 'endPoint',
        value: endPoint,
      });
    }

    if (ticket.timeOfDay) {
      const timeOfDay = this.timeOfDay.find(
        (item) => item.id === ticket.timeOfDay,
      );

      this.updateTransit({
        index,
        field: 'timeOfDay',
        value: timeOfDay,
      });
    }
  }
};

export default setTransitFunc;
