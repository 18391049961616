export const createFullPlaceInfo = ({
  region,
  area,
  city,
  settlement,
}) => ({
  region,
  district: area,
  city,
  locality: settlement,
});

export const parseFullPlaceInfo = ({
  region,
  district,
  city,
  locality,
}) => ({
  region,
  area: district,
  city,
  settlement: locality,
});

export default createFullPlaceInfo;
