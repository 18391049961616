<template>
  <div>
    <esmp-select
      :placeholder="placeholder"
      filterable
      allow-create
      show-create-item
      :loading="isLoad"
      :value="localValue"
      @on-select="setValue"
      @on-create="newOption"
    >
      <esmp-select-option
        v-for="r in hotelList"
        :key="r.hotelCode"
        :value="r.name"
        :label="r.name"
      >
        <span v-html="r.name" />
      </esmp-select-option>
    </esmp-select>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';

import { SEARCH_TIMEOUT_DELAY } from '@/constants/business-trip2';

import getDestinationCity from '../helpers/getDestinationCity';

export default {
  name: 'HotelSearch',
  props: {
    placeholder: {
      type: String,
      default: 'Гостиница',
    },
    value: {
      type: String,
      default: undefined,
    },
    city: {
      type: Object,
      default: undefined,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      hotelList: null,
      searchTimeoutId: null,
      isLoad: false,
    };
  },
  computed: {
    cityName() {
      if (!isEmpty(this.city)) {
        const city = getDestinationCity(this.city);
        return city ? city.name : '';
      }

      return '';
    },
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async search() {
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);
      this.isLoad = true;

      await this.$API.businessTrip.hotelSearch({
        city: this.cityName,
      })
        .then((response) => {
          this.isLoad = false;
          this.hotelList = response.data;
        })
        .finally(() => {
          this.isLoad = false;
        });
    },
    setValue(value) {
      this.localValue = value?.label || '';
    },
    newOption(value) {
      this.localValue = value;
    },
  },
  created() {
    if (this.city) this.search();
  },
  watch: {
    city(val) {
      this.hotelList = null;
      if (val) {
        if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);
        this.searchTimeoutId = setTimeout(this.search, SEARCH_TIMEOUT_DELAY);
      }
    },
  },
};
</script>
