import { CHECKLIST_DATA } from '@/constants/business-trip2';

const pushValueFunc = (valueArr, isEdit, editableData) => (
  id,
  value,
  valueText,
  targetArr = valueArr,
  oldValue,
  placeInfo,
) => {
  const label = CHECKLIST_DATA[id].label || null;
  const classType = CHECKLIST_DATA[id].classType || null;
  const hidden = CHECKLIST_DATA[id].hidden || false;
  const readOnly = CHECKLIST_DATA[id].readOnly || false;

  const item = {
    id,
    value,
    label,
    classType,
    valueName: valueText || value || null,
    config: {
      label,
      classType,
      hidden,
      readOnly,
    },
  };

  if (isEdit) {
    const oldValueField = oldValue || editableData[id];
    if (oldValueField !== value) {
      item.oldValue = oldValueField || null;
    }
  }

  if (placeInfo && placeInfo.placeInfoName && placeInfo.placeInfoValue) {
    item[placeInfo.placeInfoName] = placeInfo.placeInfoValue;
  }

  targetArr.push(item);
};

export default pushValueFunc;
