import dayjs from 'dayjs';

import {
  BUSINESS_TRIP_CLASS,
  BUSINESS_TRIP_TICKET_FORM,
  BUSINESS_TRIP_TRANSIT_WAYS,
} from '@/constants/business-trip2';

import { createFullPlaceInfo } from './createFullPlaceInfo';
import getDestinationCity from './getDestinationCity';

const pushTicketsFunc = (tickets, pushValue, getOldValue) => (ticket, index) => {
  const arr = [];

  pushValue(
    'ticketForm',
    BUSINESS_TRIP_TICKET_FORM[ticket.transitWay],
    BUSINESS_TRIP_TICKET_FORM[ticket.transitWay],
    arr,
    getOldValue('tickets', index, 'ticketForm'),
  );

  pushValue(
    'territoryZone',
    ticket.zone.id,
    ticket.zone.name,
    arr,
    getOldValue('tickets', index, 'territoryZone'),
  );

  const tripClassPlane = ticket.transitWay === BUSINESS_TRIP_TRANSIT_WAYS.plane
    && ticket.isBusinessClass;
  const tripClassTrain = ticket.transitWay === BUSINESS_TRIP_TRANSIT_WAYS.train
    && ticket.isFirstClass;
  const tripClass = tripClassPlane || tripClassTrain
    ? BUSINESS_TRIP_CLASS.business
    : BUSINESS_TRIP_CLASS.economy;
  pushValue(
    'tripClass',
    tripClass,
    tripClass,
    arr,
    getOldValue('tickets', index, 'tripClass'),
  );

  const ticketStartDate = dayjs(ticket.day).format(
    'YYYY-MM-DD',
  );
  pushValue(
    'ticketStartDate',
    ticketStartDate,
    ticketStartDate,
    arr,
    getOldValue('tickets', index, 'ticketStartDate'),
  );

  const tripNumber = ticket[`${ticket.transitWay}TripNumber`] || null;
  pushValue(
    'tripNumber',
    tripNumber,
    tripNumber,
    arr,
    getOldValue('tickets', index, 'tripNumber'),
  );

  pushValue(
    'departureFrom',
    getDestinationCity(ticket.startPoint).name,
    getDestinationCity(ticket.startPoint).name,
    arr,
    getOldValue('tickets', index, 'departureFrom'),
    {
      placeInfoName: 'fullPlaceInfo',
      placeInfoValue: createFullPlaceInfo(ticket.startPoint),
    },
  );
  pushValue(
    'departureTo',
    getDestinationCity(ticket.endPoint).name,
    getDestinationCity(ticket.endPoint).name,
    arr,
    getOldValue('tickets', index, 'departureTo'),
    {
      placeInfoName: 'fullPlaceInfo',
      placeInfoValue: createFullPlaceInfo(ticket.endPoint),
    },
  );

  pushValue(
    'taxService',
    '1',
    '1',
    arr,
    getOldValue('tickets', index, 'taxService'),
  );
  if (ticket.timeOfDay) {
    pushValue(
      'timeOfDay',
      ticket.timeOfDay.id,
      ticket.timeOfDay.name,
      arr,
      getOldValue('tickets', index, 'timeOfDay'),
    );
  }

  tickets.push(arr);
};

export default pushTicketsFunc;
